<script setup lang="ts">
import { computed } from 'vue'
import { definePage } from 'vue-router/auto'
import { useApi, useQuery, useSearchQuery, DataParam } from '@/composables'
import { useAuth } from '@/plugins/auth'
import {
  Badge,
  Btn,
  DateTimeFormat,
  DetailList,
  DropdownMenu,
  MenuItemLink,
  Empty,
  SearchBar,
  TransitionList,
  Panel,
} from '@/components'

definePage({
  meta: { requiresAuth: true },
})

const { term } = useSearchQuery('term')

const api = useApi()

const { self } = useAuth()

const data = computed<DataParam<'usersList'>>(() => ({
  search: term.value || undefined,
}))

const { result } = useQuery(api.v1.usersList, data)

const users = computed(() => result.value?.data?.data?.items || [])

const total = computed(() => result.value?.data?.meta?.totalItems || 0)
const filteredTotal = computed(() => users.value.length)
</script>

<template>
  <PageLayout :title="$t('pages.users.title')">
    <template #actions>
      <Btn variant="primary" :to="{ name: 'users-create' }">{{
        $t('actions.create')
      }}</Btn>
    </template>

    <template #default>
      <template v-if="users.length || term">
        <SearchBar
          v-model="term"
          :count="filteredTotal"
          :total="total"
          class="mb-5"
        />

        <TransitionList v-slot="{ item: user }" :items="users" item-key="id">
          <Panel padding="lg">
            <template #header>
              <div class="flex items-start gap-3">
                <div class="mr-auto">
                  <div>
                    <router-link
                      :to="{ name: 'users-id', params: { id: user.id ?? '' } }"
                      class="font-semibold"
                    >
                      {{ user.fullName }}
                    </router-link>

                    <span v-if="user.id === self?.user.id">
                      ({{ $t('messages.you').toLowerCase() }})
                    </span>
                  </div>

                  <div class="text-muted text-sm">
                    {{ user.primaryEmailAddress }}
                  </div>
                </div>

                <div v-if="!user.active">
                  <Badge variant="warning" outline>{{
                    $t('userStates.inactive')
                  }}</Badge>
                </div>

                <div>
                  <DropdownMenu menu-class="right-0" class="flex">
                    <MenuItemLink
                      :to="{ name: 'users-id', params: { id: user.id ?? '' } }"
                    >
                      {{ $t('actions.edit') }}
                    </MenuItemLink>
                  </DropdownMenu>
                </div>
              </div>
            </template>

            <DetailList
              :entries="user"
              :fields="['roles', user.activeAt ? 'activeAt' : '']"
              i18n-prefix="details.users"
              hide-labels
            >
              <template #roles-value="{ item }">
                <span v-if="item.roles?.length" class="flex flex-wrap gap-1">
                  {{ item.roles?.map((r) => $t(`roles.${r}`)).join(', ') }}
                </span>
                <span v-else class="text-gray-400">
                  {{ $t('messages.na') }}
                </span>
              </template>

              <template #activeAt-value="{ item }">
                {{ $t('details.users.activeAt') }}
                <DateTimeFormat v-if="item.activeAt" :date="item.activeAt" />
              </template>
            </DetailList>
          </Panel>
        </TransitionList>
      </template>

      <Empty
        v-else
        size="xl"
        image
        muted
        :title="
          $t('messages.noModelsLong', {
            model: $t('models.users').toLowerCase(),
          })
        "
      >
        <template #actions>
          <Btn variant="primary" :to="{ name: 'users-create' }">{{
            $t('actions.createModel', { model: $t('models.user') })
          }}</Btn>
        </template>
      </Empty>
    </template>
  </PageLayout>
</template>
