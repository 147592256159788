<script setup lang="ts">
import { definePage, useRouter } from 'vue-router/auto'
import { useAuth } from '@/plugins/auth'
import { Loading, PromptPanel } from '@/components'
import { arrayOverlap } from '@/utils'

definePage({
  name: 'home',
  chromeless: true,
})

const router = useRouter()

const { self } = useAuth()

router.replace({
  name: arrayOverlap(self.value?.roles ?? [], [
    'speech-language-pathologist',
    'assistant',
    'caregiver',
  ])
    ? 'patients'
    : arrayOverlap(self.value?.roles ?? [], [
        'owner',
        'directory',
        'audit-specialist',
      ])
    ? 'users'
    : 'logout',
})
</script>

<template>
  <PageLayout :title="$t('pages.home.title')">
    <PromptPanel>
      <Loading>{{ $t('messages.redirecting') }}</Loading>
    </PromptPanel>
  </PageLayout>
</template>
