<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import { Loading, PromptPanel } from '@/components'

definePage({
  redirect: { name: 'patients-id-summary' },
})
</script>

<template>
  <PageLayout :title="$t('pages.patients.title')">
    <PromptPanel>
      <Loading>{{ $t('messages.redirecting') }}</Loading>
    </PromptPanel>
  </PageLayout>
</template>
