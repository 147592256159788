<script lang="ts" setup>
import { ref, watch } from 'vue'
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { EllipsisVerticalIcon } from '@heroicons/vue/24/solid'
import { WatchEmitter } from '@/components'
import { Size, Variant, VueClassAttr } from '@@/types'
import { useElementBounding, useElementVisibility } from '@vueuse/core'

withDefaults(
  defineProps<{
    menuClass?: VueClassAttr
    buttonClass?: VueClassAttr
    buttonVariant?: Variant
    buttonSize?: Size
    buttonOutline?: boolean
    buttonBorderless?: boolean
  }>(),
  {
    menuClass: '',
    buttonClass: '',
    buttonVariant: 'primary',
    buttonSize: 'md',
    buttonBorderless: true,
  }
)

const emit = defineEmits<{
  (e: 'open', value: boolean): void
}>()

const menuButton = ref<HTMLElement | null>(null)
const { top, left, width, height, update } = useElementBounding(menuButton)

// HACK: pass the close method to this parent context when the menu opens
const close = ref<(() => void) | null>(null)
const setCloser = (fn: () => void) => {
  close.value = fn
  update()
}

// close when the menu button is no longer visible
const visible = useElementVisibility(menuButton)
watch(visible, (value) => {
  if (!value) {
    if (close.value) close.value()
    emit('open', false)
  }
})
</script>

<template>
  <Menu ref="menu" v-slot="slotProps" as="div" class="relative">
    <WatchEmitter
      :input="slotProps.open"
      @change="$emit('open', Boolean($event))"
    />
    <WatchEmitter
      :input="slotProps.open"
      @change="setCloser(slotProps.close)"
    />

    <MenuButton
      ref="menuButton"
      :variant="buttonVariant"
      :size="buttonSize"
      :borderless="buttonBorderless"
      :class="buttonClass"
    >
      <slot name="icon" v-bind="slotProps">
        <EllipsisVerticalIcon class="h-6 w-6" />
      </slot>
    </MenuButton>

    <Teleport v-if="slotProps.open" to="#menus">
      <div
        class="pointer-events-none fixed left-[var(--menu-left)] top-[var(--menu-bottom)] z-10 w-[var(--menu-width)]"
        :style="{
          '--menu-top': `${top}px`,
          '--menu-bottom': `${top + height}px`,
          '--menu-left': `${left}px`,
          '--menu-right': `${left + width}px`,
          '--menu-width': `${width}px`,
          '--menu-height': `${height}px`,
        }"
      >
        <MenuItems
          class="group pointer-events-auto absolute top-0"
          :class="menuClass"
        >
          <div
            class="flex min-w-[200px] flex-col overflow-hidden overflow-y-auto rounded border border-gray-300 bg-white shadow-lg ring-primary-500/50 group-focus:border-primary-500 group-focus:outline-none group-focus:ring-4 dark:border-gray-700 dark:bg-gray-800"
          >
            <slot v-bind="slotProps" />
          </div>
        </MenuItems>
      </div>
    </Teleport>
  </Menu>
</template>
