<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { definePage, useRoute } from 'vue-router/auto'
import { useRouteQuery } from '@vueuse/router'
import {
  Goal,
  Phoneme,
  PhonemeQuery,
  defaultPhonemeQuery,
  Utterance,
  UtteranceType,
  UtteranceQuery,
  GoalState,
} from '@/models'
import { useApi, useQuery, useRange } from '@/composables'
import {
  Badge,
  Boundary,
  Btn,
  DropdownMenu,
  Empty,
  MenuItemButton,
  MenuItemDivider,
  Panel,
  SortList,
} from '@/components'
import { toArray } from '@/utils'
import { EllipsisVerticalIcon } from '@heroicons/vue/24/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'

definePage({
  meta: { requiresAuth: true },
})

const route = useRoute('patients-id-goals-goalid')
const userId = route.params.id

const api = useApi()

// TODO: language code should be dynamic
const { result: phonemesRes } = useQuery(
  api.v1.languagesPhonemesDetail,
  'en-US'
)
// TODO: .phonemes should be .items
const phonemes = computed(() => phonemesRes.value?.data?.data?.items ?? [])
// TODO: merge this with phonemes computed
const phonemesFlat = computed(() => {
  return (phonemes.value as Phoneme[]).flatMap((phoneme) => {
    if (phoneme.children) {
      return [phoneme, ...phoneme.children]
    }

    return phoneme
  })
})

const encodedQueries = (queries: PhonemeQuery[]) => {
  return queries.map((query) => {
    const encoded = {
      i: query.initial,
      m: query.medial,
      f: query.final,
      o: query.occursOnce,
      x: query.occursMany,
    }

    return (
      query.phonemeId +
      ':' +
      Object.entries(encoded)
        .filter(([, value]) => value)
        .map(([key]) => key)
        .join('') +
      ':' +
      query.cluster
    )
  })
}

const decodeQueries = (raws: string | string[] | null) => {
  return toArray(raws).map((raw) => {
    const [phonemeId, encoded, cluster] = raw.split(':')

    const decoded = Object.fromEntries(
      ['i', 'm', 'f', 'o', 'x'].map((key) => [key, encoded.includes(key)])
    )

    return defaultPhonemeQuery({
      phonemeId: phonemeId === 'null' ? undefined : phonemeId,
      initial: decoded.i,
      medial: decoded.m,
      final: decoded.f,
      occursOnce: decoded.o,
      occursMany: decoded.x,
      cluster: cluster as PhonemeQuery['cluster'],
    })
  })
}

const targetPhonemes = ref<PhonemeQuery[]>([])
const targetPhonemesRoute = useRouteQuery('targets')

watch(
  targetPhonemesRoute,
  (val, oldVal) => {
    if (!val) return
    // prevent infinite loops from the watchers fighting
    if (JSON.stringify(val) === JSON.stringify(oldVal)) return

    targetPhonemes.value = decodeQueries(toArray(val))
  },
  { immediate: true }
)

watch(
  targetPhonemes,
  (val) => {
    if (!val) return

    targetPhonemesRoute.value = encodedQueries(val)
  },
  { deep: true }
)

const targetPhonemesClean = computed(() =>
  targetPhonemes.value.filter(({ phonemeId }) => phonemeId)
)

const addTargetPhoneme = () => {
  targetPhonemes.value.push(
    defaultPhonemeQuery({
      initial: true,
      medial: true,
      final: true,
      occursOnce: true,
      occursMany: true,
    })
  )
}

const removeTargetPhoneme = (index: number) => {
  targetPhonemes.value.splice(index, 1)
}

// TODO: this might be overkill using objects, we could use an array of strings
const avoidPhonemes = ref<PhonemeQuery[]>([])
const avoidPhonemesRoute = useRouteQuery('avoids')

watch(
  avoidPhonemesRoute,
  (val, oldVal) => {
    if (!val) return
    // prevent infinite loops from the watchers fighting
    if (JSON.stringify(val) === JSON.stringify(oldVal)) return

    avoidPhonemes.value = decodeQueries(toArray(val))
  },
  { immediate: true }
)

watch(
  avoidPhonemes,
  (val) => {
    if (!val) return

    avoidPhonemesRoute.value = encodedQueries(val)
  },
  { deep: true }
)

const addAvoidPhoneme = () => {
  avoidPhonemes.value.push(defaultPhonemeQuery())
}

const removeAvoidPhoneme = (index: number) => {
  avoidPhonemes.value.splice(index, 1)
}

const term = useRouteQuery('term', '')

const minSyllables = 1
const maxSyllables = 12

const lowerSyllablesQuery = useRouteQuery<number>('sylmin', minSyllables)
const upperSyllablesQuery = useRouteQuery<number>('sylmax', maxSyllables)

const { lower: lowerSyllables, upper: upperSyllables } = useRange(
  minSyllables,
  maxSyllables
)

// keep the query params in sync
watch([lowerSyllables, upperSyllables], ([minVal, maxVal]) => {
  lowerSyllablesQuery.value = minVal
  upperSyllablesQuery.value = maxVal
})

const minWords = 1
const maxWords = 9

const lowerWordsQuery = useRouteQuery<number>('wordmin', minWords)
const upperWordsQuery = useRouteQuery<number>('wordmax', maxWords)

const { lower: lowerWords, upper: upperWords } = useRange(minWords, maxWords)

// keep the query params in sync
watch([lowerWords, upperWords], ([minVal, maxVal]) => {
  lowerWordsQuery.value = minVal
  upperWordsQuery.value = maxVal
})

const scope = useRouteQuery<UtteranceType>('scope', 'word')

const searchParams = computed<UtteranceQuery>(() => {
  return {
    includePhonemeQueries: targetPhonemesClean.value,
    excludePhonemeIds: avoidPhonemes.value.map((query) => query.phonemeId),
    // for now, UI only allows one type at a time
    utteranceTypes: [scope.value],
    search: term.value,
    syllablesMin: lowerSyllables.value,
    syllablesMax: upperSyllables.value,
    wordsMin: lowerWords.value,
    wordsMax: upperWords.value,
    pageIndex: 0,
    pageSize: 100,
  }
})

// TODO: fix useQuery so it can handle api.v1.languagesUtterancesCreate
const resultsRes = ref<Awaited<
  ReturnType<typeof api.v1.languagesUtterancesCreate>
> | null>(null)

const totalResults = computed(() => {
  return resultsRes.value?.data.meta.totalItems ?? 0
})

watch(
  searchParams,
  async (val) => {
    if (!val) return
    if (val.includePhonemeQueries.length === 0) return

    // the function is called 'create' because it uses a POST to search
    resultsRes.value = await api.v1.languagesUtterancesCreate(
      'en-US',
      searchParams.value
    )
  },
  { immediate: true }
)

const canSearch = computed(() => targetPhonemesClean.value.length > 0)

// show or clear results if at least one target phoneme exists
const results = computed<Utterance[]>(() =>
  canSearch.value ? resultsRes.value?.data.data.items ?? [] : []
)

const { result: goalRes } = useQuery(api.v1.goalsDetail, route.params.goalid)

const goal = ref<Goal | null>(null)
watch(goalRes, (value) => {
  if (!value.data) return
  goal.value = value.data.data.item
  title.value = goal.value?.title
})

const canEdit = computed(() => {
  if (!goal.value) return false

  return goal.value.state === 'draft'
})

// cache ids to make it easier to look up which items have been added
const itemIds = computed(
  () => goal.value?.items.map((item) => item.utteranceId) ?? []
)

const addItems = async (utterances: Utterance[]) => {
  if (!goal.value) return
  // !itemIds.value.includes(utterance.id)

  const res = await api.v1.goalsItemsCreate(goal.value.id, {
    utteranceIds: utterances.map((utterance) => utterance.id),
    // TODO: remove map once type accept occursOnce and occursMany
    targetPhonemeSpecifications: targetPhonemesClean.value.map((p) => ({
      phonemeId: p.phonemeId,
      initial: p.initial,
      medial: p.medial,
      final: p.final,
    })),
  })

  goal.value.items = res.data.data.item.items ?? []
}

const removeItems = async (utteranceIds: string[]) => {
  if (!goal.value) return

  // get goal item IDs from utterance IDs
  const itemIds = goal.value.items
    .filter((item) => utteranceIds.includes(item.utteranceId))
    .map((item) => item.id)

  const res = await api.v1.goalsItemsDelete(goal.value.id, { itemIds })

  goal.value = res.data.data.item
}

const toggleItem = (item: Utterance) => {
  if (itemIds.value.includes(item.id)) {
    removeItems([item.id])
  } else {
    addItems([item])
  }
}

const allSelected = computed(() => {
  if (!results.value.length) return false

  return results.value.every((result) => itemIds.value.includes(result.id))
})

const toggleAllNone = () => {
  if (!results.value.length) return

  if (allSelected.value) {
    removeItems(results.value.map((result) => result.id))
  } else {
    addItems(results.value)
  }
}

const moveItem = async (id: string, index: number) => {
  if (!goal.value) return
  if (index < 0 || index > goal.value.items.length - 1) return

  const res = await api.v1.goalsItemsPriorityUpdate(goal.value.id, id, {
    priority: index,
  })

  goal.value.items = res.data.data.item.items ?? []
}

const setState = async (state: GoalState) => {
  const res = await api.v1.goalsStateUpdate(route.params.goalid, { state })
  goal.value = res.data.data.item
}

const setImitation = async (imitation: boolean) => {
  const res = await api.v1.goalsUpdate(route.params.goalid, { imitation })
  goal.value = res.data.data.item
}

// TODO: consider creating an inline editable field component or dynamic form
const title = ref('')

const editingTitle = ref(false)
const editTitle = () => {
  editingTitle.value = true
}

const updateTitle = async () => {
  if (!goal.value) return

  const res = await api.v1.goalsTitleUpdate(goal.value.id, {
    title: title.value,
  })
  goal.value = res.data.data.item
  editingTitle.value = false
}

const revertTitle = () => {
  title.value = goal.value?.title ?? ''
  editingTitle.value = false
}
</script>

<template>
  <PatientLayout
    :title="goal?.title ?? ''"
    :breadcrumbs="[
      { text: $t('nav.goals'), to: { name: 'patients-id-goals' } },
    ]"
    :user-id="userId"
  >
    <div v-if="goal" class="grid gap-10" :class="{ 'xl:grid-cols-2': canEdit }">
      <div v-if="canEdit">
        <section class="mb-5">
          <div class="flex gap-2">
            <label for="title" class="sr-only">{{
              $t('forms.sounds.name')
            }}</label>
            <input
              id="title"
              v-model="title"
              type="text"
              :readonly="!editingTitle"
            />

            <div class="inline-flex gap-1">
              <template v-if="editingTitle">
                <Btn outline @click="revertTitle()">{{
                  $t('actions.cancel')
                }}</Btn>
                <Btn variant="primary" outline @click="updateTitle()">{{
                  $t('actions.save')
                }}</Btn>
              </template>
              <Btn v-else outline @click="editTitle()">{{
                $t('actions.edit')
              }}</Btn>
            </div>
          </div>
        </section>

        <section class="mb-5">
          <header class="mb-5">
            <h2 class="text-base font-semibold">
              {{ $t('pages.goalsEdit.targetSounds') }}
            </h2>
          </header>

          <div v-for="(sound, i) in targetPhonemes" :key="i" class="mb-5">
            <div class="w-full">
              <div class="mb-3 flex gap-2">
                <div class="grow">
                  <label :for="`target-phoneme-${i}`" class="sr-only">{{
                    $t('forms.sounds.phoneme')
                  }}</label>
                  <select
                    :id="`target-phoneme-${i}`"
                    v-model="sound.phonemeId"
                    required
                  >
                    <option
                      v-for="phoneme in phonemesFlat"
                      :key="phoneme.id"
                      :value="phoneme.id"
                    >
                      <span v-if="phoneme.parentId">&nbsp;&rdca;&nbsp;</span>
                      <span>{{ phoneme.title }}</span>
                      <span v-if="phoneme.example"
                        >&nbsp;{{ phoneme.example }}</span
                      >
                    </option>
                  </select>
                </div>

                <div>
                  <Btn variant="danger" outline @click="removeTargetPhoneme(i)">
                    <XMarkIcon class="h-6 w-6" />
                  </Btn>
                </div>
              </div>

              <div class="flex flex-wrap gap-5 text-sm">
                <div class="flex items-center gap-3 whitespace-nowrap">
                  <input
                    :id="`target-initial-${i}`"
                    v-model="sound.initial"
                    type="checkbox"
                  />
                  <label :for="`target-initial-${i}`"
                    >&larr; {{ $t('forms.sounds.initial') }}</label
                  >
                </div>

                <div class="flex items-center gap-3 whitespace-nowrap">
                  <input
                    :id="`target-medial-${i}`"
                    v-model="sound.medial"
                    type="checkbox"
                  />
                  <label :for="`target-medial-${i}`"
                    >&harr; {{ $t('forms.sounds.medial') }}</label
                  >
                </div>

                <div class="flex items-center gap-3 whitespace-nowrap">
                  <input
                    :id="`target-final-${i}`"
                    v-model="sound.final"
                    type="checkbox"
                  />
                  <label :for="`target-final-${i}`"
                    >&rarr; {{ $t('forms.sounds.final') }}</label
                  >
                </div>

                <div class="flex items-center gap-3 whitespace-nowrap">
                  <input
                    :id="`target-once-${i}`"
                    v-model="sound.occursOnce"
                    type="checkbox"
                  />
                  <label :for="`target-once-${i}`">{{
                    $t('forms.sounds.single')
                  }}</label>
                </div>

                <div class="flex items-center gap-3 whitespace-nowrap">
                  <input
                    :id="`target-many-${i}`"
                    v-model="sound.occursMany"
                    type="checkbox"
                  />
                  <label :for="`target-many-${i}`">{{
                    $t('forms.sounds.multiple')
                  }}</label>
                </div>
              </div>

              <div class="mb-3">
                <p
                  v-if="!(sound.initial || sound.medial || sound.final)"
                  class="text-sm text-danger-500"
                >
                  {{ $t('forms.sounds.locationError') }}
                </p>
                <p
                  v-else-if="!(sound.occursOnce || sound.occursMany)"
                  class="text-sm text-danger-500"
                >
                  {{ $t('forms.sounds.occurrenceError') }}
                </p>
              </div>

              <div class="flex gap-5 text-sm">
                <div class="flex items-center gap-3">
                  <input
                    :id="`cluster-with-${i}`"
                    v-model="sound.cluster"
                    value="include"
                    type="radio"
                    :name="`cluster-${i}`"
                  />
                  <label :for="`cluster-with-${i}`">{{
                    $t('clusters.with')
                  }}</label>
                </div>

                <div class="flex items-center gap-3">
                  <input
                    :id="`cluster-without-${i}`"
                    v-model="sound.cluster"
                    value="exclude"
                    type="radio"
                    :name="`cluster-${i}`"
                  />
                  <label :for="`cluster-without-${i}`">{{
                    $t('clusters.without')
                  }}</label>
                </div>

                <div class="flex items-center gap-3">
                  <input
                    :id="`cluster-only-${i}`"
                    v-model="sound.cluster"
                    value="only"
                    type="radio"
                    :name="`cluster-${i}`"
                  />
                  <label :for="`cluster-only-${i}`">
                    {{ $t('clusters.only') }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <Btn outline class="w-full" @click="addTargetPhoneme">{{
              $t('actions.add')
            }}</Btn>
          </footer>
        </section>

        <section class="mb-5">
          <header class="mb-5">
            <h2 class="text-base font-semibold">
              {{ $t('pages.goalsEdit.avoidSounds') }}
            </h2>
          </header>

          <div v-for="(sound, i) in avoidPhonemes" :key="i" class="flex gap-5">
            <div class="mb-3 flex w-full gap-2">
              <label :for="`avoid-phoneme-${i}`" class="sr-only">{{
                $t('forms.sounds.phoneme')
              }}</label>
              <select
                :id="`avoid-phoneme-${i}`"
                v-model="sound.phonemeId"
                required
              >
                <option
                  v-for="phoneme in phonemesFlat"
                  :key="phoneme.id"
                  :value="phoneme.id"
                >
                  <span v-if="phoneme.parentId">&nbsp;&rdca;&nbsp;</span>
                  <span>{{ phoneme.title }}</span>
                  <span v-if="phoneme.example"
                    >&nbsp;{{ phoneme.example }}</span
                  >
                </option>
              </select>

              <div>
                <Btn variant="danger" outline @click="removeAvoidPhoneme(i)">
                  <XMarkIcon class="h-6 w-6" />
                </Btn>
              </div>
            </div>
          </div>

          <footer>
            <Btn outline class="w-full" @click="addAvoidPhoneme">{{
              $t('actions.add')
            }}</Btn>
          </footer>
        </section>

        <section class="space-y-5">
          <header class="mb-5">
            <h2 class="text-base font-semibold">
              {{ $t('pages.goalsEdit.searchUtterances') }}
            </h2>
          </header>

          <div>
            <label for="search" class="sr-only">Search</label>
            <input id="search" v-model.trim="term" type="search" />
          </div>

          <div class="grid grid-cols-2 gap-5">
            <div>
              <label for="min-syllables">Min Syllables</label>
              <input
                id="min-syllables"
                v-model="lowerSyllables"
                type="number"
                :min="minSyllables"
                :max="maxSyllables"
              />
            </div>

            <div>
              <label for="max-syllables">Max Syllables</label>
              <input
                id="max-syllables"
                v-model="upperSyllables"
                type="number"
                :min="minSyllables"
                :max="maxSyllables"
              />
            </div>

            <div>
              <label for="min-words">Min Words</label>
              <input
                id="min-words"
                v-model="lowerWords"
                type="number"
                :min="minWords"
                :max="maxWords"
              />
            </div>

            <div>
              <label for="max-words">Max Words</label>
              <input
                id="max-words"
                v-model="upperWords"
                type="number"
                :min="minWords"
                :max="maxWords"
              />
            </div>
          </div>

          <div
            class="grid grid-cols-3 border-b border-gray-300 dark:border-gray-700"
          >
            <div>
              <input
                id="scope-words"
                v-model="scope"
                value="word"
                type="radio"
                name="scope"
                class="peer sr-only"
              />
              <label
                for="scope-words"
                class="block w-full cursor-pointer border-b-4 border-transparent p-2 text-center peer-checked:!border-primary-500 dark:hover:border-neutral-700"
                >Words</label
              >
            </div>

            <div>
              <input
                id="scope-phrases"
                v-model="scope"
                value="phrase"
                type="radio"
                name="scope"
                class="peer sr-only"
              />
              <label
                for="scope-phrases"
                class="block w-full cursor-pointer border-b-4 border-transparent p-2 text-center peer-checked:!border-primary-500 dark:hover:border-neutral-700"
                >Phrases</label
              >
            </div>

            <div>
              <input
                id="scope-sentences"
                v-model="scope"
                value="sentence"
                type="radio"
                name="scope"
                class="peer sr-only"
              />
              <label
                for="scope-sentences"
                class="block w-full cursor-pointer border-b-4 border-transparent p-2 text-center peer-checked:!border-primary-500 dark:hover:border-neutral-700"
                >Sentences</label
              >
            </div>
          </div>

          <div class="flex justify-between">
            <div v-if="results.length" class="px-5">
              <input
                id="selectAll"
                type="checkbox"
                :checked="allSelected"
                @change="toggleAllNone"
              />
              <label for="selectAll" class="ml-3">{{
                $t(allSelected ? 'actions.selectNone' : 'actions.selectAll')
              }}</label>
            </div>

            <div v-if="results.length" class="text-muted text-sm">
              {{
                $t('messages.resultCount', {
                  count: results.length,
                  total: totalResults,
                })
              }}
            </div>
          </div>

          <Empty
            v-if="results.length === 0"
            muted
            image
            :title="$t('messages.noResults')"
          />

          <ul class="space-y-2">
            <li v-for="result in results" :key="result.id">
              <Panel
                as="label"
                :for="`result_${result.id}`"
                :class="{
                  'opacity-50': itemIds.includes(result.id),
                }"
                class="block"
                body-class="pl-5 flex gap-5 items-center cursor-pointer"
              >
                <div>
                  <input
                    :id="`result_${result.id}`"
                    type="checkbox"
                    :checked="itemIds.includes(result.id)"
                    @change="toggleItem(result)"
                  />
                </div>

                <div>
                  {{ result.text }}
                </div>

                <div class="text-muted ml-auto">
                  {{ result.syllableCount }} syllables
                </div>
              </Panel>
            </li>
          </ul>
        </section>
      </div>

      <aside class="relative">
        <Boundary
          :include="['top', 'inner-height']"
          class="sticky top-3 flex flex-col xl:h-[calc(var(--inner-height)-max(var(--top),0px))]"
        >
          <header class="mb-5 flex justify-between">
            <h2 class="text-base font-semibold">
              {{ $t('pages.goalsEdit.items') }}
            </h2>

            <div v-if="goal.items.length" class="text-muted text-sm">
              {{ $t('messages.itemCount', { count: goal.items.length }) }}
            </div>
          </header>

          <section class="mb-5 md:overflow-y-auto">
            <template v-if="goal.items.length">
              <SortList
                v-model="goal.items"
                item-key="id"
                class="mb-5 space-y-2"
                :locked="goal.state !== 'draft'"
                @change="
                  moveItem($event.moved.element.id, $event.moved.newIndex)
                "
              >
                <template #default="{ element: item, index: i }">
                  <Panel body-class="flex gap-5 justify-between">
                    <div class="flex items-center gap-3">
                      <span
                        v-if="goal.state === 'draft'"
                        class="handle -space-x-4 hover:cursor-grab"
                      >
                        <EllipsisVerticalIcon class="inline h-6" />
                        <EllipsisVerticalIcon class="inline h-6" />
                      </span>

                      <span>
                        <div>{{ item.utteranceText }}</div>
                        <ul class="flex items-center gap-1">
                          <li
                            v-for="phoneme in item.targetPhonemes"
                            :key="phoneme.phonemeId"
                          >
                            <Badge class="!text-xs"
                              >{{ phoneme.phonemeTitle }}
                              <div class="inline-flex items-center gap-1">
                                <span
                                  v-if="phoneme.initial"
                                  :title="$t('forms.sounds.initial')"
                                  >&larr;</span
                                >
                                <span
                                  v-if="phoneme.medial"
                                  :title="$t('forms.sounds.medial')"
                                  >&harr;</span
                                >
                                <span
                                  v-if="phoneme.final"
                                  :title="$t('forms.sounds.final')"
                                  >&rarr;</span
                                >
                              </div>
                            </Badge>
                          </li>
                        </ul>
                      </span>
                    </div>

                    <div v-if="canEdit" class="flex items-center gap-1">
                      <DropdownMenu menu-class="right-0">
                        <MenuItemButton
                          :disabled="i === 0"
                          @click="moveItem(item.id, i - 1)"
                        >
                          {{ $t('actions.moveUp') }}
                        </MenuItemButton>

                        <MenuItemButton
                          :disabled="i === goal.items.length - 1"
                          @click="moveItem(item.id, i + 1)"
                        >
                          {{ $t('actions.moveDown') }}
                        </MenuItemButton>

                        <MenuItemButton
                          :disabled="i === 0"
                          @click="moveItem(item.id, 0)"
                        >
                          {{ $t('actions.moveTop') }}
                        </MenuItemButton>

                        <MenuItemButton
                          :disabled="i === goal.items.length - 1"
                          @click="moveItem(item.id, goal.items.length - 1)"
                        >
                          {{ $t('actions.moveBottom') }}
                        </MenuItemButton>

                        <MenuItemDivider />

                        <MenuItemButton
                          @click="removeItems([item.utteranceId])"
                        >
                          {{ $t('actions.remove') }}
                        </MenuItemButton>
                      </DropdownMenu>
                    </div>
                  </Panel>
                </template>
              </SortList>
            </template>

            <Empty v-else image muted :title="$t('messages.noItems')" />
          </section>

          <footer v-if="canEdit" class="bg-white pb-5 dark:bg-gray-900">
            <div class="mb-3">
              <input
                id="imitation"
                v-model="goal.imitation"
                type="checkbox"
                :disabled="!canEdit"
                @change="setImitation(goal.imitation)"
              />
              <label for="imitation" class="ml-3">{{
                $t('forms.goals.imitation')
              }}</label>
            </div>

            <Btn
              variant="primary"
              class="w-full"
              :disabled="goal.items.length === 0"
              @click="setState('active')"
              >{{ $t('actions.activate') }}</Btn
            >
          </footer>
        </Boundary>
      </aside>
    </div>
  </PatientLayout>
</template>
