<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { definePage, useRoute } from 'vue-router/auto'
import { Bar, ChartProps } from 'vue-chartjs'
import dayjs from 'dayjs'
import { DataParam, useApi, useQuery } from '@/composables'
import { Btn } from '@/components'
import { GoalMetricIntervals } from '@/models'
import { DateLike } from '@@/types'

type BarChartProps = ChartProps<'bar', { x: DateLike; y: number }[], unknown>

definePage({
  meta: { requiresAuth: true },
})

const route = useRoute('patients-id-goals-goalid-metrics')
const userId = route.params.id
const api = useApi()

const interval = ref<GoalMetricIntervals>('daily')
const page = ref(0)
const showUnrated = ref(true)

// Reset page to start when interval changes
watch(interval, () => {
  page.value = 0
})

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC'

const { transformed: goal } = useQuery(
  api.v1.goalsDetail,
  route.params.goalid,
  { transform: (result) => result?.data.data.item }
)

const params = computed<DataParam<'goalsMetricsIntervalsDetail'>>(() => {
  return {
    id: route.params.goalid,
    timezone,
    interval: interval.value,
  }
})

const { transformed: metrics } = useQuery(
  api.v1.goalsMetricsIntervalsDetail,
  params,
  { transform: (result) => result?.data?.data?.items || [] }
)

const chartXMin = computed(() =>
  dayjs(chartStartTime.value).subtract(page.value, timeUnit.value).valueOf()
)
const chartXMax = computed(() =>
  dayjs().subtract(page.value, timeUnit.value).valueOf()
)

const getDataset = (
  rating: 'fair' | 'good' | 'poor' | 'unrated',
  label: string,
  backgroundColor: string
) => {
  return computed<BarChartProps['data']['datasets'][number]>(() => {
    const data =
      metrics.value
        ?.filter((metric) => {
          return (
            !dayjs(metric.startsAt).isAfter(chartXMax.value) &&
            !dayjs(metric.startsAt).isBefore(chartXMin.value)
          )
        })
        .map((metric) => {
          return {
            x: metric.startsAt,
            y: metric.ratings[rating].total,
            p: metric.ratings[rating].percentage,
          }
        }) || []

    return {
      label,
      backgroundColor,
      data,
      borderRadius: 10,
    }
  })
}

const goodDataset = getDataset('good', 'Good', '#00BD80')
const fairDataset = getDataset('fair', 'Fair', '#F08245')
const poorDataset = getDataset('poor', 'Poor', '#EB5E54')
const unratedDataset = getDataset('unrated', 'Unrated', '#B3B3B7')

const intervalCount = computed(() => {
  return {
    daily: 7,
    weekly: 4,
    monthly: 12,
    yearly: 5,
  }[interval.value]
})

const timeUnit = computed(() => {
  return (
    {
      daily: 'day',
      weekly: 'week',
      monthly: 'month',
      yearly: 'year',
    } as const
  )[interval.value]
})

const chartStartTime = computed(() => {
  return {
    daily: dayjs().subtract(intervalCount.value, 'day').valueOf(),
    weekly: dayjs().subtract(intervalCount.value, 'week').valueOf(),
    monthly: dayjs().subtract(intervalCount.value, 'month').valueOf(),
    yearly: dayjs().subtract(intervalCount.value, 'year').valueOf(),
  }[interval.value]
})

const chartOptions = computed<BarChartProps['options']>(() => ({
  responsive: true,
  animation: false,
  scales: {
    y: {
      stacked: true,
      ticks: {
        precision: 0,
      },
      grid: {
        drawTicks: false,
      },
      border: {
        dash: [5, 10],
        width: 0,
      },
    },
    x: {
      type: 'time',
      time: {
        unit: timeUnit.value,
        isoWeekday: 1, // Start weeks on Monday
      },
      grid: {
        display: false,
      },
      // Always want to show the full time range
      min: chartXMin.value,
      max: chartXMax.value,
      stacked: true,
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        title: () => '',
        label: (data) => {
          const label = data.dataset.label
          const count = data.parsed.y
          const percentage = Math.round((data.raw as { p: number }).p * 10) / 10

          return `${label}: ${count} (${percentage}%)`
        },
      },
    },
  },
}))

const chartData = computed<BarChartProps['data']>(() => ({
  datasets: [
    goodDataset.value,
    fairDataset.value,
    poorDataset.value,
    ...(showUnrated.value ? [unratedDataset.value] : []),
  ],
}))

const newer = () => {
  if (page.value > 0) {
    page.value--
  }
}
const older = () => {
  page.value++
}
</script>

<template>
  <PatientLayout
    :title="$t('pages.goalsMetrics.title')"
    :breadcrumbs="[
      {
        text: $t('nav.goals'),
        to: { name: 'patients-id-goals', params: route.params },
      },
      {
        text: goal && goal.title,
        to: { name: 'patients-id-goals-goalid', params: route.params },
      },
    ]"
    :user-id="userId"
  >
    <div class="flex gap-3">
      <select v-model="interval">
        <option value="daily">{{ $t('pages.goalsMetrics.daily') }}</option>
        <option value="weekly">{{ $t('pages.goalsMetrics.weekly') }}</option>
        <option value="monthly">{{ $t('pages.goalsMetrics.monthly') }}</option>
        <option value="yearly">{{ $t('pages.goalsMetrics.yearly') }}</option>
      </select>

      <Btn variant="primary" @click="older">«</Btn>
      <Btn variant="primary" @click="newer">»</Btn>
    </div>
    <div class="relative">
      <!-- @vue-ignore: `data` for Bar component is typed more strictly than what it actually allows -->
      <Bar :options="chartOptions" :data="chartData" />
    </div>

    <input
      id="show-unrated"
      v-model="showUnrated"
      type="checkbox"
      class="mr-2"
    />
    <label for="show-unrated">
      {{ $t('pages.goalsMetrics.includeUnrated') }}
    </label>
  </PatientLayout>
</template>
