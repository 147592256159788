<script setup lang="ts">
import { computed, ref } from 'vue'
import { definePage, useRoute } from 'vue-router/auto'
import { useApi } from '@/composables'
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline'
import { Btn, Countdown, PromptPanel } from '@/components'
import { Passcode } from '@/models'
import { useClipboard } from '@vueuse/core'
import { PatientLayout } from '@/layouts'

definePage({
  meta: { requiresAuth: true },
})

const route = useRoute('patients-id-passcode')
const userId = route.params.id

const api = useApi()

const passcode = ref<Passcode | null>(null)
const passcodeText = computed(() => passcode.value?.text ?? '')

const { copy, isSupported: clipboardSupported } = useClipboard({
  source: passcodeText,
})

const duration = computed(() => {
  if (!passcode.value) return 0

  return (
    new Date(passcode.value?.expiresAt as string).getTime() -
      new Date(passcode.value?.createdAt as string).getTime() ?? 0
  )
})

const generate = async () => {
  const res = await api.v1.usersPasscodesCreate(userId, { type: 'short' })

  passcode.value = res.data.data.item
}

const reset = () => {
  passcode.value = null
}
</script>

<template>
  <PatientLayout :title="$t('pages.patientsPasscode.title')" :user-id="userId">
    <PromptPanel>
      <template v-if="passcode">
        <div
          class="relative mb-5 block rounded-lg bg-gray-200 p-5 px-12 text-center dark:bg-gray-950"
        >
          <span class="font-mono text-4xl tracking-widest">{{
            passcodeText
          }}</span>

          <button
            v-if="clipboardSupported"
            class="absolute right-0 top-0 flex aspect-square h-full items-center justify-center border-0 text-gray-500"
            @click="copy()"
          >
            <DocumentDuplicateIcon class="h-6 w-6" />
          </button>
        </div>

        <Countdown
          v-slot="{ remainingFormatted }"
          :duration="duration"
          :delay="1000"
          @complete="reset"
        >
          {{ $t('messages.passcodeExpires', { time: remainingFormatted }) }}
        </Countdown>
      </template>

      <template v-else>
        <p class="mb-8">
          {{ $t('pages.patientsPasscode.description') }}
        </p>

        <Btn variant="primary" class="w-full" @click="generate">{{
          $t('actions.generatePasscode')
        }}</Btn>
      </template>
    </PromptPanel>
  </PatientLayout>
</template>
