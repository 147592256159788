<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/auto'
import { useAuth } from '@/plugins/auth'
import { useDark, useMagicKeys, useToggle } from '@vueuse/core'
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  DocumentTextIcon,
  IdentificationIcon,
  ListBulletIcon,
  LockClosedIcon,
  MicrophoneIcon,
  MoonIcon,
  PresentationChartLineIcon,
  SunIcon,
  UserIcon,
} from '@heroicons/vue/24/outline'
import { useOutdated } from '@/composables'
import {
  Avatar,
  Logo,
  IdleMonitor,
  RouteActive,
  Sidebar,
  SidebarItem,
} from '@/components'

const { isAuthenticated, self, logout } = useAuth()

const allowDarkMode = import.meta.env.VITE_ALLOW_DARK_MODE === 'true'

const isDark = useDark()

// force light mode if dark mode is not allowed
if (!allowDarkMode) {
  isDark.value = false
} else {
  const keys = useMagicKeys()
  const ctrlAltShiftD = keys['Ctrl+Alt+Shift+D']

  watch(ctrlAltShiftD, (value) => {
    if (value) {
      isDark.value = !isDark.value
    }
  })
}

const toggleDark = useToggle(isDark)

const route = useRoute()
const router = useRouter()

watch(isAuthenticated, () => {
  if (!isAuthenticated.value && route.name !== 'login') {
    router.push({ name: 'login' })
  }
})

const hasPendingLicenseAgreements = computed(
  () => self.value?.pendingLicenseAgreements.length
)

const rtf = new Intl.RelativeTimeFormat('en', { style: 'long' })

const idleTimeout = parseInt(import.meta.env.VITE_IDLE_MS ?? 5 * 60 * 1000)
const cutoffTimeout = parseInt(
  import.meta.env.VITE_IDLE_CUTOFF_MS ?? 5 * 60 * 1000
)

const chromeless = ref(true)
// avoid a flash of chrome on first load
onMounted(async () => {
  await router.isReady()
  chromeless.value = route.meta.chromeless === true
})
watch(
  () => route.meta.chromeless,
  (value) => {
    chromeless.value = value === true
  }
)

const isOutdated = useOutdated(1000 * 60 * 5) // 5 minutes

const refreshPage = () => {
  window.location.reload()
}
</script>

<template>
  <div>
    <Sidebar
      :chromeless="chromeless"
      sidebar-class="bg-gray-100 text-gray-900 dark:text-white dark:bg-gray-900"
    >
      <template #aside>
        <div class="flex h-full flex-col justify-between">
          <nav>
            <router-link
              :to="{ name: 'home' }"
              class="block p-5 text-2xl font-thin text-inherit hover:bg-white/5 hover:no-underline focus:bg-white/5 focus:outline-none dark:border-gray-800 dark:text-white"
            >
              <Logo />
            </router-link>

            <div v-if="!hasPendingLicenseAgreements">
              <SidebarItem
                v-if="isAuthenticated"
                :to="{ name: 'patients' }"
                :icon="IdentificationIcon"
                exact
                >{{ $t('nav.patients') }}</SidebarItem
              >

              <RouteActive v-if="isAuthenticated" :to="{ name: 'patients' }">
                <nav>
                  <SidebarItem
                    :to="{ name: 'patients-id-summary' }"
                    :icon="PresentationChartLineIcon"
                    class="pl-10"
                    >{{ $t('nav.summary') }}</SidebarItem
                  >

                  <SidebarItem
                    :to="{ name: 'patients-id-record' }"
                    :icon="DocumentTextIcon"
                    class="pl-10"
                    >{{ $t('nav.patientRecord') }}</SidebarItem
                  >

                  <SidebarItem
                    :to="{ name: 'patients-id-goals' }"
                    :icon="ListBulletIcon"
                    class="pl-10"
                    >{{ $t('nav.goals') }}</SidebarItem
                  >

                  <SidebarItem
                    :to="{ name: 'patients-id-recordings' }"
                    :icon="MicrophoneIcon"
                    class="pl-10"
                    >{{ $t('nav.recordings') }}</SidebarItem
                  >

                  <SidebarItem
                    :to="{ name: 'patients-id-passcode' }"
                    :icon="LockClosedIcon"
                    class="pl-10"
                    >{{ $t('nav.passcode') }}</SidebarItem
                  >
                </nav>
              </RouteActive>

              <SidebarItem
                v-if="isAuthenticated"
                :to="{ name: 'users' }"
                :icon="UserIcon"
                >{{ $t('nav.users') }}</SidebarItem
              >
            </div>
          </nav>

          <nav>
            <SidebarItem
              v-if="allowDarkMode"
              :icon="isDark ? SunIcon : MoonIcon"
              @click="toggleDark()"
              >{{ isDark ? 'Light' : 'Dark' }}</SidebarItem
            >

            <div v-if="!hasPendingLicenseAgreements">
              <SidebarItem
                v-if="isAuthenticated"
                :to="{ name: 'profile' }"
                class="w-full"
              >
                <div class="flex items-center gap-3">
                  <div>
                    <Avatar :name="self?.user.fullName" />
                  </div>

                  <div>
                    <div>{{ self?.user.fullName }}</div>
                    <div
                      class="text-muted text-sm group-[.router-link-exact-active]:text-secondary-800 dark:group-[.router-link-exact-active]:text-white"
                    >
                      {{ self?.organization.name }}
                    </div>
                  </div>

                  <div class="ml-auto">
                    <router-link
                      :to="{ name: 'logout' }"
                      class="-mr-2 block rounded-full p-2 text-dark hover:bg-white dark:!text-white dark:hover:bg-gray-700"
                    >
                      <ArrowLeftOnRectangleIcon class="h-5 w-5 -scale-100" />
                    </router-link>
                  </div>
                </div>
              </SidebarItem>
            </div>
          </nav>
        </div>
      </template>

      <template #default="{ state, toggle }">
        <header
          v-if="!chromeless && state !== 'pinned'"
          class="relative z-20 flex border-b border-transparent bg-gray-200 dark:border-gray-800 dark:bg-gray-900 dark:text-white"
        >
          <router-link
            :to="{ name: 'home' }"
            class="p-5 text-2xl font-thin text-inherit hover:no-underline"
          >
            <Logo />
          </router-link>

          <nav class="ml-auto">
            <button class="p-5 text-inherit" @click="toggle">
              <Bars3Icon class="h-6 w-6" />
            </button>
          </nav>
        </header>
        <main class="container mx-auto max-w-screen-xl px-5 py-10 lg:px-10">
          <suspense>
            <router-view v-slot="{ Component, route: pageRoute }">
              <component :is="Component" :key="pageRoute.path" />
            </router-view>
          </suspense>
        </main>
      </template>
    </Sidebar>
  </div>

  <IdleMonitor
    v-slot="{ idleFor, remaining }"
    :idle-timeout="idleTimeout"
    :cutoff-timeout="cutoffTimeout"
    :disabled="!isAuthenticated"
    @cutoff="logout"
  >
    {{
      $t('messages.idle', {
        time: rtf.format(-1 * Math.floor(idleFor / 1000 / 60), 'minutes'),
        cutoff: rtf.format(Math.ceil(remaining / 1000 / 60), 'minutes'),
      })
    }}
  </IdleMonitor>

  <button
    v-if="isOutdated"
    class="fixed inset-x-0 top-0 z-50 bg-info-500 p-3 text-center text-white shadow-lg transition-colors hover:bg-info-600"
    @click="refreshPage"
  >
    {{ $t('messages.outdated') }}
  </button>

  <div id="modals" />
  <div id="menus" />
</template>
