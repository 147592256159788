<script setup lang="ts">
import { ref } from 'vue'
import { useRouter, definePage } from 'vue-router/auto'
import { User, defaultUser } from '@/models'
import { DynamicForm, userDetailsSchema } from '@/forms'
import { Alert, Panel } from '@/components'
import { useApi } from '@/composables'
import { useAuth } from '@/plugins/auth'
import { useI18n } from 'vue-i18n'
import type { AxiosError } from 'axios'

definePage({
  meta: { requiresAuth: true },
})

const router = useRouter()
const api = useApi()
const { self } = useAuth()
const { t } = useI18n()

const user = ref(defaultUser())

const errorMessage = ref('')

const submit = async (values: User) => {
  try {
    const response = await api.v1.usersCreate({
      fullName: values.fullName ?? '',
      emailAddress: values.primaryEmailAddress,
      organizationId: self.value?.organization.id ?? '',
      active: true,
    })

    router.push({
      name: 'users-id',
      params: { id: response.data.data?.item?.id ?? '' },
    })
  } catch (err) {
    const axiosError = err as AxiosError<{ failure: string }>
    errorMessage.value =
      axiosError?.response?.data?.failure === 'duplicate.emailAddress'
        ? t('messages.errorDuplicateEmail')
        : t('messages.error')

    console.error(err)
  }
}
</script>

<template>
  <PageLayout
    :title="$t('pages.usersCreate.title')"
    :breadcrumbs="[{ text: $t('pages.users.title'), to: { name: 'users' } }]"
  >
    <Panel header-class="font-semibold" footer-class="space-x-2">
      <Alert v-if="errorMessage" variant="danger" class="mb-3">{{
        errorMessage
      }}</Alert>

      <DynamicForm
        :schema="userDetailsSchema"
        :initial-values="user"
        :submit-text="$t('actions.save')"
        fields-class="grid grid-cols-2 gap-8 !space-y-0"
        @submit="submit"
      />
    </Panel>
  </PageLayout>
</template>
