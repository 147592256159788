<script setup lang="ts">
import { ref } from 'vue'
import { definePage, useRouter, useRoute } from 'vue-router/auto'
import { DynamicForm, passwordResetUpdateSchema } from '@/forms'
import { PanelLayout } from '@/layouts'
import { useApi } from '@/composables'
import { Alert, Background, PasswordRules } from '@/components'
import { Fail, Error } from '@/models'
import background from '@/assets/background.svg'
import { AxiosError } from 'axios'

definePage({
  meta: { chromeless: true },
})

const api = useApi()

const router = useRouter()
const route = useRoute('password-reset-id')

const id = route.params.id
const code = Array.isArray(route.query.code)
  ? route.query.code[0]
  : route.query.code

const showError = ref(false)

const send = async ({
  newPassword,
}: {
  newPassword: string
  confirmPassword: string
}) => {
  showError.value = false

  try {
    await api.v1.usersPasswordsResetsUpdate(id, {
      code: code || '',
      newPassword,
    })

    router.push({ name: 'login', query: { passwordReset: 'success' } })
  } catch (err) {
    const res = err as AxiosError<{
      failure?: string
      status: string
    }>

    const payload = res.response?.data ?? {}

    if ('failure' in payload && payload.failure === 'invalid.password.weak') {
      showError.value = true
    } else if (
      (payload as Fail).status === 'fail' ||
      (payload as Error).status === 'error'
    ) {
      router.replace({ name: 'password-reset-invalid' })
    }
  }
}
</script>

<template>
  <PanelLayout :title="$t('pages.passwordResetEdit.title')">
    <Background :url="background" class="bg-gray-100" />

    <h1 class="mb-5 text-xl">{{ $t('pages.passwordResetEdit.title') }}</h1>

    <Alert class="mb-5">
      <PasswordRules class="text-sm" />
    </Alert>

    <Alert v-if="showError" variant="danger" class="mb-5">{{
      $t('messages.errorInvalidPassword')
    }}</Alert>

    <DynamicForm
      :schema="passwordResetUpdateSchema"
      :submit-btn-props="{ class: '!block w-full' }"
      :submit-text="$t('actions.updatePassword')"
      @submit="send"
    />
  </PanelLayout>
</template>
