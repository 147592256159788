<script setup lang="ts">
import { RouteLocationRaw, useLink } from 'vue-router/auto'

const props = defineProps<{
  to: RouteLocationRaw
}>()

const { isActive, isExactActive } = useLink({ to: props.to })

// TODO: handle attrs?
</script>

<template>
  <slot v-if="isExactActive" name="exact" />
  <slot v-else-if="isActive" />
</template>
