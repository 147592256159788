<script setup lang="ts">
import { computed, ref } from 'vue'
import { useAuth } from '@/plugins/auth'
import { useApi } from '@/composables'
import { Alert, Avatar, Btn, Panel, PasswordRules } from '@/components'
import { DynamicForm, passwordUpdateSchema } from '@/forms'
import { definePage } from 'vue-router/auto'

definePage({
  meta: { requiresAuth: true },
})

const { self } = useAuth()
const user = computed(() => self.value?.user)

const schema = passwordUpdateSchema()

const api = useApi()

const invalidPasswordError = ref(false)

const updatePassword = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}) => {
  if (!user.value?.id) return

  try {
    await api.v1.usersPasswordsUpdate(user.value.id, {
      currentPassword,
      newPassword,
    })
  } catch (err) {
    console.error(err)
    invalidPasswordError.value = true
  }
}
</script>

<template>
  <PageLayout :title="$t('pages.profile.title')">
    <template #actions>
      <Btn variant="danger" :to="{ name: 'logout' }">{{
        $t('actions.logout')
      }}</Btn>
    </template>

    <div class="space-y-8">
      <Panel as="section" body-class="flex gap-5 items-center">
        <Avatar class="h-20 w-20" :name="user?.fullName ?? ''" />
        <div>{{ user?.fullName }}</div>
      </Panel>

      <section>
        <header class="mb-5">
          <h2 class="font-body text-base font-semibold">
            {{ $t('pages.profile.password') }}
          </h2>
        </header>

        <div class="grid gap-5 lg:grid-cols-2 lg:gap-8">
          <div>
            <Alert v-if="invalidPasswordError" variant="danger" class="mb-5">
              {{ $t('messages.invalidPassword') }}
            </Alert>

            <DynamicForm
              :schema="schema"
              :submit-text="$t('actions.updatePassword')"
              :submit-btn-props="{ outline: true }"
              @submit="updatePassword"
            />
          </div>

          <div class="-order-1 lg:order-1 lg:pt-6">
            <Alert variant="info">
              <PasswordRules />
            </Alert>
          </div>
        </div>
      </section>
    </div>
  </PageLayout>
</template>
