<script setup lang="ts">
import { computed } from 'vue'
import { definePage, useRoute } from 'vue-router/auto'
import { useApi, useQuery } from '@/composables'
import {
  Btn,
  DetailList,
  Panel,
  // ProgressBar,
  Empty,
  DateTimeFormat,
} from '@/components'
import dayjs from 'dayjs'

definePage({
  meta: { requiresAuth: true },
})

const route = useRoute('patients-id')
const userId = route.params.id

const api = useApi()

const { result: recordRes } = useQuery(api.v1.usersRecordsPatientDetail, userId)
const record = computed(() => recordRes.value?.data?.data?.item)

const defaults = computed(() => recordRes.value?.data?.meta.defaults)

// TODO: fetch real data
// const goals = [
//   { id: 'g111', name: 'Placeholder goal', percent: 0.3 },
//   { id: 'g222', name: 'Another placeholder', percent: 0.7 },
//   { id: 'g333', name: 'This is a goal', percent: 0.5 },
//   { id: 'g444', name: 'Complete goal', percent: 1 },
// ]

// TODO: fetch real data
// const recordings = [
//   { id: 'r111', name: 'Placeholder recording 1' },
//   { id: 'r222', name: 'Placeholder recording 2' },
//   { id: 'r333', name: 'Placeholder recording 3' },
//   { id: 'r444', name: 'Placeholder recording 4' },
// ]
</script>

<template>
  <PatientLayout :title="$t('pages.patientsSummary.title')" :user-id="userId">
    <div class="grid grid-cols-2 gap-5">
      <Panel
        v-if="record"
        class="col-span-2"
        header-class="flex items-center justify-between"
      >
        <template #header>
          <div>
            <h2 class="font-body text-base">
              <router-link :to="{ name: 'patients-id-record' }">
                {{ $t('pages.patientsSummary.record') }}
              </router-link>
            </h2>
          </div>

          <div>
            <router-link :to="{ name: 'patients-id-record' }" class="text-sm">
              {{ $t('actions.edit') }}
            </router-link>
          </div>
        </template>

        <template #default>
          <DetailList
            :entries="record"
            :fields="[
              'birthYear',
              'birthMonth',
              'playSeconds',
              'practiceCount',
              'language',
              'automaticallyAdvance',
              'provideFeedback',
            ]"
            i18n-prefix="details.patientRecord"
          >
            <template #birthYear-value="{ item }">
              {{
                item.birthYear ?? 0 > 1900 ? item.birthYear : $t('messages.na')
              }}
            </template>

            <template #birthMonth-value="{ item }">
              <DateTimeFormat
                v-if="item.birthMonth"
                :date="dayjs().month(item.birthMonth - 1)"
                :options="{ month: 'long' }"
              />
              <span v-else>{{ $t('messages.na') }}</span>
            </template>

            <template #playSeconds-value="{ item }">
              {{
                item.playSeconds ||
                $t('messages.defaultModel', { model: defaults?.playSeconds })
              }}
            </template>

            <template #practiceCount-value="{ item }">
              {{
                item.practiceCount ||
                $t('messages.defaultModel', { model: defaults?.practiceCount })
              }}
            </template>

            <template #language-value="{ item }">
              <span v-if="item.languageCode">
                {{ $t(`languages.${item.languageCode}`) }}
              </span>

              <span v-else>
                {{
                  $t('messages.defaultModel', {
                    model: defaults?.language?.name?.[0].value,
                  })
                }}
              </span>
            </template>

            <template #automaticallyAdvance-value="{ item }">
              <span v-if="item.automaticallyAdvance != null">
                {{
                  item.automaticallyAdvance
                    ? $t('messages.yes')
                    : $t('messages.no')
                }}
              </span>
              <span v-else>
                {{
                  $t('messages.defaultModel', {
                    model: defaults?.automaticallyAdvance
                      ? $t('messages.yes')
                      : $t('messages.no'),
                  })
                }}
              </span>
            </template>

            <template #provideFeedback-value="{ item }">
              <span v-if="item.provideFeedback != null">
                {{
                  item.provideFeedback ? $t('messages.yes') : $t('messages.no')
                }}
              </span>
              <span v-else>
                {{
                  $t('messages.defaultModel', {
                    model: defaults?.provideFeedback
                      ? $t('messages.yes')
                      : $t('messages.no'),
                  })
                }}
              </span>
            </template>
          </DetailList>
        </template>
      </Panel>

      <Empty
        v-else
        :title="$t('pages.patientsRecord.noRecord')"
        image
        size="md"
        class="col-span-2"
      >
        <template #actions>
          <router-link :to="{ name: 'patients-id-record' }">
            <Btn variant="primary">
              {{ $t('actions.addModel', { model: $t('models.record') }) }}
            </Btn>
          </router-link>
        </template>
      </Empty>

      <!-- <Panel header-class="flex items-center justify-between">
        <template #header>
          <div>
            <h2 class="font-body text-base font-semibold">
              <router-link
                :to="{ name: 'patients-id-goals' }"
                class="text-dark dark:text-white"
              >
                {{ $t('pages.patientsSummary.goals') }}
              </router-link>
            </h2>
          </div>

          <div>
            <router-link :to="{ name: 'patients-id-goals' }" class="text-sm">
              {{ $t('actions.edit') }}
            </router-link>
          </div>
        </template>

        <template #default>
          <ul>
            <li
              v-for="goal in goals"
              :key="goal.id"
              class="grid grid-cols-2 items-center gap-5 py-3 last:border-0 dark:border-gray-700"
            >
              <div>
                {{ goal.name }}
              </div>

              <div>
                <ProgressBar :value="goal.percent" />
              </div>
            </li>
          </ul>
        </template>
      </Panel>

      <Panel header-class="flex items-center justify-between">
        <template #header>
          <div>
            <h2 class="font-body text-base font-semibold">
              <router-link
                :to="{ name: 'patients-id-recordings' }"
                class="text-dark dark:text-white"
              >
                {{ $t('pages.patientsSummary.recordings') }}
              </router-link>
            </h2>
          </div>

          <div>
            <router-link
              :to="{ name: 'patients-id-recordings' }"
              class="text-sm"
            >
              {{ $t('actions.edit') }}
            </router-link>
          </div>
        </template>

        <template #default>
          <ul>
            <li
              v-for="recording in recordings"
              :key="recording.id"
              class="flex items-center justify-between gap-5 py-3 last:border-0 dark:border-gray-700"
            >
              <div>
                {{ recording.name }}
              </div>

              <div>
                <Btn size="sm" outline>{{ $t('actions.play') }}</Btn>
              </div>
            </li>
          </ul>
        </template>
      </Panel> -->
    </div>
  </PatientLayout>
</template>
