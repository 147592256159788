<script lang="ts" setup>
import { ref } from 'vue'
import { definePage, useRoute, useRouter } from 'vue-router/auto'
import { useAuth } from '@/plugins/auth'
import { Alert, Background, Btn, Logo } from '@/components'
import { DynamicForm, loginSchema } from '@/forms'
import { PanelLayout } from '@/layouts'
import background from '@/assets/background.svg'

definePage({
  meta: { chromeless: true },
})

const router = useRouter()
const route = useRoute()
const { login: authLogin, isAuthenticated } = useAuth()

const showError = ref(false)

if (isAuthenticated.value) {
  router.push({ name: 'home' })
}

const loading = ref(false)

const login = async (data: {
  organizationShortName: string
  emailAddress: string
  password: string
}) => {
  console.log(data)
  showError.value = false

  loading.value = true
  const success = await authLogin(
    data.organizationShortName,
    data.emailAddress,
    data.password
  )
  loading.value = false

  showError.value = !success
  if (!success) return

  // if supplied, redirect to the previous page after login
  const redirect = route.query.redirect
  if (redirect) {
    router.replace(redirect as string)
    // otherwise, redirect to the appropriate page based on the user's role
  } else {
    router.replace({ name: 'home' })
  }
}
</script>

<template>
  <Background :url="background" class="bg-gray-100" />

  <PanelLayout :title="$t('pages.login.title')">
    <template #header>
      <Logo class="mx-auto !h-auto !w-1/2" />
    </template>

    <h1 class="mb-5 text-xl">{{ $t('pages.login.title') }}</h1>

    <Alert v-if="showError" variant="danger" class="mb-5">{{
      $t('messages.errorInvalidLogin')
    }}</Alert>

    <DynamicForm
      :schema="loginSchema"
      :submit-btn-props="{ class: 'w-full' }"
      :submit-text="$t('actions.login')"
      :pending="loading"
      @submit="login"
    />

    <div class="mt-5">
      <Btn :to="{ name: 'password-reset' }" borderless class="w-full">
        {{ $t('actions.forgotPassword') }}
      </Btn>
    </div>
  </PanelLayout>
</template>
