<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import { PanelLayout } from '@/layouts'
import { Background, Btn } from '@/components'
import background from '@/assets/background.svg'

definePage({
  meta: { chromeless: true },
})
</script>

<template>
  <PanelLayout :title="$t('pages.passwordResetSent.title')">
    <Background :url="background" class="bg-gray-100" />

    <h1 class="mb-5 text-xl">{{ $t('pages.passwordResetInvalid.title') }}</h1>

    <p class="mb-8">{{ $t('pages.passwordResetInvalid.description') }}</p>

    <template #actions>
      <Btn
        variant="primary"
        outline
        class="!block w-full"
        :to="{ name: 'login' }"
      >
        {{ $t('actions.returnToSignIn') }}
      </Btn>
    </template>
  </PanelLayout>
</template>
