<script setup lang="ts">
import { computed, ref } from 'vue'
import { definePage, useRoute } from 'vue-router/auto'
import { useApi, useQuery } from '@/composables'
import {
  GoalSummary,
  RatingValue,
  Recording,
  RecordingsListParams,
} from '@/models'
import {
  Autocomplete,
  DetailList,
  Empty,
  ModalDialog,
  Panel,
  RecordingReview,
  TransitionList,
  DateTimeFormat,
  Badge,
} from '@/components'

definePage({
  meta: { requiresAuth: true },
})

const ratingVariants = {
  unrated: 'neutral',
  poor: 'danger',
  fair: 'warning',
  good: 'success',
  ignored: 'info',
} as const

const route = useRoute('patients-id-recordings')
const userId = route.params.id

const api = useApi()

const ratingFilter = ref<
  RecordingsListParams['filterByLatestRatingValue'] | ''
>('')

const { transformed: goalsResult } = useQuery(
  api.v1.goalsList,
  { userId: route.params.id, state: ['active', 'inactive', 'archived'] },
  {
    transform: (result) => result?.data.data.items || [],
  }
)
const goal = ref<GoalSummary>()

const { transformed: recordings } = useQuery(
  api.v1.recordingsList,
  computed(() => ({
    filterByUserId: route.params.id,
    filterByGoalId: goal.value?.id,
    filterByLatestRatingValue: ratingFilter.value || undefined,
  })),
  {
    transform: (result) => result?.data.data.items || [],
  }
)

const updateRating = async (recordingId: string, rating: RatingValue) => {
  const res = await api.v1.recordingsRatingsCreate({
    recordingId,
    value: rating,
  })
  const recording = recordings.value?.find((r) => r.id === recordingId)
  if (recording) {
    recording.latestRating = res.data.data.item
  }
}

const reviewRecording = ref<Recording | null>(null)
const setReviewRecording = async (recording: Recording | null) => {
  reviewRecording.value = recording
}

const reviewPrevious = (current: Recording) => {
  const index = recordings.value?.findIndex((r) => r.id === current.id)

  if (index !== undefined && index !== -1) {
    const nextIndex = index + 1
    if (nextIndex < (recordings.value?.length ?? 0)) {
      reviewRecording.value = recordings.value?.[nextIndex] ?? null
    }
  }
}

const reviewNext = (current: Recording) => {
  const index = recordings.value?.findIndex((r) => r.id === current.id)

  if (index !== undefined && index !== -1) {
    const previousIndex = index - 1
    if (previousIndex >= 0) {
      reviewRecording.value = recordings.value?.[previousIndex] ?? null
    }
  }
}
</script>

<template>
  <PatientLayout
    :title="$t('pages.patientsRecordings.title')"
    :user-id="userId"
  >
    <section v-if="recordings?.length">
      <header class="mb-5 flex flex-col gap-3 md:flex-row md:items-center">
        <label for="goal-filter">
          {{ $t('forms.patientsRecordings.goal') }}
        </label>

        <Autocomplete
          v-model="goal"
          input-id="goal-filter"
          class="grow basis-1/2 md:mr-5"
          field="title"
          :items="goalsResult || []"
        />

        <label for="rating-filter-select">
          {{ $t('forms.patientsRecordings.rating') }}
        </label>

        <select
          id="rating-filter-select"
          v-model="ratingFilter"
          class="basis-1/2"
        >
          <option value="">{{ $t('pages.patientsRecordings.all') }}</option>
          <option value="none">
            {{ $t('pages.patientsRecordings.none') }}
          </option>
          <option value="good">
            {{ $t('pages.patientsRecordings.good') }}
          </option>
          <option value="fair">
            {{ $t('pages.patientsRecordings.fair') }}
          </option>
          <option value="poor">
            {{ $t('pages.patientsRecordings.poor') }}
          </option>
          <option value="ignored">
            {{ $t('pages.patientsRecordings.ignored') }}
          </option>
        </select>
      </header>

      <TransitionList
        v-slot="{ item: recording }"
        :items="recordings || []"
        item-key="id"
      >
        <Panel
          header-class="flex gap-3 items-center"
          class="cursor-pointer"
          :class="
            reviewRecording?.id === recording.id && 'ring-4 ring-primary-500/50'
          "
          @click="setReviewRecording(recording)"
        >
          <template #header>
            <div class="flex w-full flex-col gap-3 md:flex-row">
              <div>
                <a class="font-semibold">{{ recording.utteranceText }}</a>

                <ul class="flex flex-col gap-1 md:flex-row md:items-center">
                  <li
                    v-for="phoneme in recording.targetPhonemes"
                    :key="phoneme.phonemeId"
                  >
                    <Badge class="text-xs">
                      {{ phoneme.phonemeTitle }}
                      <div class="inline-flex items-center gap-1">
                        <span
                          v-if="phoneme.initial"
                          :title="$t('forms.sounds.initial')"
                          >&larr;</span
                        >
                        <span
                          v-if="phoneme.medial"
                          :title="$t('forms.sounds.medial')"
                          >&harr;</span
                        >
                        <span
                          v-if="phoneme.final"
                          :title="$t('forms.sounds.final')"
                          >&rarr;</span
                        >
                      </div>
                    </Badge>
                  </li>
                </ul>
              </div>

              <div class="md:ml-auto">
                <Badge
                  :variant="
                    ratingVariants[recording.latestRating?.value ?? 'unrated']
                  "
                  >{{
                    $t(`ratings.${recording.latestRating?.value ?? 'unrated'}`)
                  }}</Badge
                >
              </div>
            </div>
          </template>

          <DetailList
            :entries="recording"
            :fields="['createdAt', 'imitation', 'ratedBy', 'ratedOn']"
            i18n-prefix="details.recordings"
            fallback
          >
            <template #createdAt-value="{ item }">
              <DateTimeFormat
                :date="item.createdAt"
                :options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              />
            </template>
            <template #imitation-value="{ item }">
              {{ item.imitation ? $t('messages.yes') : $t('messages.no') }}
            </template>

            <template #ratedBy-value="{ item }">
              {{ item.latestRating?.userFullName ?? $t('messages.na') }}
            </template>

            <template #ratedOn-value="{ item }">
              <DateTimeFormat
                v-if="item.latestRating?.createdAt"
                :date="item.latestRating?.createdAt"
                :options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              />
              <span v-else>{{ $t('messages.na') }}</span>
            </template>
          </DetailList>
        </Panel>
      </TransitionList>
    </section>

    <Empty
      v-else
      size="xl"
      image
      muted
      :title="
        $t('messages.noModelsLong', {
          model: $t('models.recordings').toLowerCase(),
        })
      "
    />

    <ModalDialog
      :open="Boolean(reviewRecording)"
      closeable
      :title="reviewRecording?.utteranceText ?? ''"
      @close="setReviewRecording(null)"
    >
      <RecordingReview
        v-if="reviewRecording"
        :recording="reviewRecording"
        @rate="updateRating(reviewRecording.id, $event)"
        @previous="reviewPrevious"
        @next="reviewNext"
      />
    </ModalDialog>
  </PatientLayout>
</template>
