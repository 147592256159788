<script setup lang="ts">
import { Avatar, Breadcrumb, DropdownMenu, Panel } from '@/components'
import { RouteLocationRaw } from 'vue-router/auto'
import { useApi, useQuery } from '@/composables'

const props = withDefaults(
  defineProps<{
    title: string
    pageTitle?: string
    breadcrumbs?: { text: string; to?: RouteLocationRaw }[]
    userId: string
  }>(),
  {
    pageTitle: undefined,
    breadcrumbs: () => [],
  }
)

const api = useApi()

const { transformed: user } = useQuery(api.v1.usersDetail, props.userId, {
  transform: (res) => res?.data.data?.item,
})
</script>

<template>
  <UseHead>
    <title>{{ pageTitle ?? title }}</title>
  </UseHead>

  <article class="@container">
    <header class="mb-10 grid gap-10 @2xl:grid-cols-[1fr,33%]">
      <div>
        <Breadcrumb
          trailing-separator
          class="text-muted mr-4"
          :items="[
            { text: $t('nav.patients'), to: { name: 'patients' } },
            {
              text: user?.fullName ?? '',
              to: { name: 'patients-id-summary', params: { id: userId } },
            },
            ...breadcrumbs,
          ]"
        />

        <div class="flex items-center gap-3">
          <h1>{{ title }}</h1>

          <div class="ml-auto">
            <slot name="actions" />
          </div>

          <DropdownMenu
            v-if="$slots.menu"
            menu-class="right-0"
            button-class="p-2 rounded hover:bg-primary-500 focus:bg-primary-500 transition-colors duration-200 ease-in-out outline-none focus:outline-none"
          >
            <slot name="menu" />
          </DropdownMenu>
        </div>
      </div>

      <div>
        <Panel
          as="RouterLink"
          :to="{ name: 'patients-id-summary', params: { id: userId } }"
          class="block"
          body-class="flex gap-3 items-center"
        >
          <Avatar :name="user?.fullName" />

          <span class="font-semibold">{{ user?.fullName }}</span>
        </Panel>
      </div>
    </header>

    <div>
      <slot />
    </div>
  </article>
</template>
