import { Plugin } from 'vue'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  TimeScale,
} from 'chart.js'
import 'chartjs-adapter-dayjs-4'

export default {
  install(_app) {
    ChartJS.register(
      Title,
      Tooltip,
      Legend,
      BarElement,
      LinearScale,
      TimeScale,
    )
  },
} as Plugin
