<script setup lang="ts">
import { computed, ref } from 'vue'
import { definePage, useRoute } from 'vue-router/auto'
import { DynamicForm, patientRecordSchema } from '@/forms'
import { useApi, useQuery } from '@/composables'
import {
  Alert,
  Badge,
  DetailList,
  DateTimeFormat,
  Empty,
  Panel,
} from '@/components'
import { PatientRecord, defaultPatientRecord } from '@/models'

definePage({
  meta: { requiresAuth: true },
})

// const router = useRouter()
const route = useRoute('patients-id')
const userId = route.params.id

const api = useApi()

const { result: versionsRes, execute } = useQuery(
  api.v1.usersRecordsPatientVersionsDetail,
  { id: userId }
)

const versions = computed(() => versionsRes.value?.data?.data?.items ?? [])

const { result: userRes } = useQuery(api.v1.usersDetail, userId)
const user = computed(() => userRes.value?.data?.data?.item)

const record = ref(defaultPatientRecord())

// TODO: change type back to `InstanceType<typeof DynamicForm> | null` once
// the following is fixed https://github.com/vuejs/core/issues/8373
const form = ref<
  (ReturnType<typeof DynamicForm> & { reset: () => void }) | null
>(null)
const reset = () => {
  form.value?.reset()
}

const saving = ref(false)
const save = async (values: PatientRecord) => {
  saving.value = true
  await api.v1.usersRecordsPatientCreate(userId, values)
  await execute()
  saving.value = false
  reset()
}
</script>

<template>
  <PageLayout
    :title="$t('pages.patientsRecord.title')"
    :breadcrumbs="[{ text: $t('nav.patients'), to: { name: 'patients' } }]"
  >
    <Panel class="mb-10">
      {{ user?.fullName }}
    </Panel>

    <div class="grid gap-10 xl:grid-cols-[2fr,1fr]">
      <div class="xl:order-1">
        <DynamicForm
          v-if="user"
          ref="form"
          :schema="patientRecordSchema"
          :initial-values="record"
          :submit-text="$t('actions.save')"
          :pending="saving"
          @submit="save($event)"
        />
      </div>

      <aside>
        <header>
          <h2 class="mb-2 text-lg font-medium">
            {{ $t('pages.patientsRecord.versions') }}
          </h2>
        </header>

        <Alert v-if="versions.length === 0">{{ $t('messages.noItems') }}</Alert>

        <ul v-if="versions" class="space-y-2">
          <Panel
            v-for="version in versions"
            :key="version.id"
            as="li"
            header-class="flex items-center gap-3 justify-between"
            :class="{ 'opacity-50': !version.latest }"
          >
            <template #header>
              <DateTimeFormat :date="version.createdAt" />

              <div v-if="version.latest">
                <Badge>{{ $t('messages.latest') }}</Badge>
              </div>
            </template>

            <template #default>
              <DetailList
                :entries="version"
                :fields="[
                  'birthYear',
                  'birthMonth',
                  'playMinutes',
                  'practiceCount',
                  'defaultLanguageCode',
                  'automaticallyAdvance',
                  'provideFeedback',
                ]"
                i18n-prefix="details.patientRecord"
                fallback
              />
            </template>
          </Panel>
        </ul>

        <Empty
          v-else
          image
          muted
          :title="
            $t('messages.noModelsLong', {
              model: $t('models.versions').toLowerCase(),
            })
          "
        />
      </aside>
    </div>
  </PageLayout>
</template>
