<script setup lang="ts">
import { computed, ref, nextTick, watch } from 'vue'
import { definePage, useRoute, useRouter } from 'vue-router/auto'
import { DynamicForm, DynamicField, patientRecordSchema } from '@/forms'
import { useApi, useQuery } from '@/composables'
import { PatientRecord, defaultPatientRecord } from '@/models'
import { watchOnce } from '@vueuse/core'
import { Field } from 'vee-validate'

definePage({
  meta: { requiresAuth: true },
})

const route = useRoute('patients-id')
const router = useRouter()
const userId = route.params.id

const api = useApi()

const { transformed: user } = useQuery(api.v1.usersDetail, userId, {
  transform: (res) => res?.data.data?.item,
})

const { result: recordRes, error: recordErr } = useQuery(
  api.v1.usersRecordsPatientDetail,
  userId
)

const fallbackRecord = defaultPatientRecord({
  playSeconds: null,
  practiceCount: null,
  languageCode: null,
  automaticallyAdvance: null,
  provideFeedback: null,
  birthMonth: null,
  birthYear: null,
})

const record = ref<PatientRecord>({ ...fallbackRecord })
watch([recordRes, recordErr], ([recordValue]) => {
  record.value = recordValue?.data?.data?.item ?? { ...fallbackRecord }
})

const defaults = computed<Partial<PatientRecord & { languageName: string }>>(
  () => {
    // Even on a 404, the error response will include a meta.defaults object
    const defaults =
      recordRes.value?.data?.meta.defaults ??
      recordErr.value?.response?.data?.meta?.defaults

    if (!defaults) return {}

    return {
      ...defaults,
      languageCode: defaults.language.code,
      // HACK: so we can show the human value of the default language
      languageName: defaults.language.name?.[0].value ?? '',
    }
  }
)

// TODO: Use api results to populate language select options
const { result: languageRes } = useQuery(api.v1.languagesList, {})
const languageOptions = computed(
  () =>
    languageRes.value?.data?.data?.items?.map((i) => ({
      label: i.name?.[0].value || '',
      value: i.code || '',
    })) || []
)

const saving = ref(false)
const save = async (values: PatientRecord) => {
  // Nullify all fields that are marked as using default values
  let toSend = Object.fromEntries(
    Object.entries(values).map(([k, v]) =>
      defaultFields.value[k as keyof PatientRecord] ? [k, null] : [k, v]
    )
  )

  // birthYear and birthMonth are optional, so nullify them if they are empty
  toSend = {
    ...toSend,
    birthYear: toSend.birthYear || null,
    birthMonth: toSend.birthMonth || null,
  }

  saving.value = true
  try {
    await api.v1.usersRecordsPatientCreate(userId, toSend)
    router.push({ name: 'patients-id-summary' })
  } catch (e) {
    console.error(e)
  }
  saving.value = false
}

// On initial load, set default fields to true if the record's field value is
// null and set the default value on the record itself
const defaultFields = ref<Partial<{ [k in keyof PatientRecord]: boolean }>>({
  playSeconds: true,
  practiceCount: true,
  languageCode: true,
})

watchOnce(record, async (value) => {
  defaultFields.value = {
    playSeconds: value.playSeconds == null,
    practiceCount: value.practiceCount == null,
    languageCode: value.languageCode == null,
  }

  await nextTick()

  record.value = {
    ...record.value,
    playSeconds: value.playSeconds ?? defaults.value.playSeconds,
    practiceCount: value.practiceCount ?? defaults.value.practiceCount,
    languageCode: value.languageCode ?? defaults.value.languageCode,
  } as PatientRecord
})
</script>

<template>
  <PatientLayout :title="$t('pages.patientsRecord.title')" :user-id="userId">
    <DynamicForm
      v-if="user"
      ref="form"
      :schema="patientRecordSchema(languageOptions)"
      :initial-values="record"
      :submit-text="$t('actions.save')"
      :pending="saving"
      @submit="save($event)"
    >
      <template #field_playSeconds="{ field, setFieldValue, setFieldTouched }">
        <DynamicField :field="field" :disabled="defaultFields[field.name]" />
        <div>
          <input
            :id="`${field.name}-default`"
            v-model="defaultFields[field.name]"
            type="checkbox"
            class="mr-2"
            @change="
              setFieldValue(field.name, defaults[field.name]),
                setFieldTouched(field.name, true)
            "
          />
          <label :for="`${field.name}-default`">
            {{ $t('actions.useDefault') }}
            <span class="text-muted"> ({{ defaults[field.name] }}) </span>
          </label>
        </div>
      </template>

      <template
        #field_practiceCount="{ field, setFieldValue, setFieldTouched }"
      >
        <DynamicField :field="field" :disabled="defaultFields[field.name]" />
        <div>
          <input
            :id="`${field.name}-default`"
            v-model="defaultFields[field.name]"
            type="checkbox"
            class="mr-2"
            @change="
              setFieldValue(field.name, defaults[field.name]),
                setFieldTouched(field.name, true)
            "
          />
          <label :for="`${field.name}-default`">
            {{ $t('actions.useDefault') }}
            <span class="text-muted"> ({{ defaults[field.name] }}) </span>
          </label>
        </div>
      </template>

      <template #field_languageCode="{ field, setFieldValue, setFieldTouched }">
        <DynamicField :field="field" :disabled="defaultFields[field.name]" />
        <div>
          <input
            :id="`${field.name}-default`"
            v-model="defaultFields[field.name]"
            type="checkbox"
            class="mr-2"
            @change="
              setFieldValue(field.name, defaults[field.name]),
                setFieldTouched(field.name, true)
            "
          />
          <label :for="`${field.name}-default`">
            {{ $t('actions.useDefault') }}
            <span class="text-muted"> ({{ defaults.languageName }}) </span>
          </label>
        </div>
      </template>

      <!-- TODO: Look into using DynamicField and with the schema for these -->
      <template #field_automaticallyAdvance="{ field }">
        <fieldset>
          <legend class="mb-1 block">{{ field.label }}</legend>
          <div class="space-x-3">
            <span>
              <Field
                :id="`${field.name}-yes`"
                :name="field.name"
                type="radio"
                :value="true"
                class="mr-2"
              />
              <label :for="`${field.name}-yes`">
                {{ $t('messages.yes') }}
              </label>
            </span>

            <span>
              <Field
                :id="`${field.name}-no`"
                :name="field.name"
                type="radio"
                :value="false"
                class="mr-2"
              />
              <label :for="`${field.name}-no`">
                {{ $t('messages.no') }}
              </label>
            </span>

            <span>
              <Field
                :id="`${field.name}-default`"
                :name="field.name"
                type="radio"
                :value="null"
                class="mr-2"
              />
              <label :for="`${field.name}-default`">
                {{ $t('actions.useDefault') }}
                <span class="text-muted">
                  ({{
                    defaults[field.name]
                      ? $t('messages.yes')
                      : $t('messages.no')
                  }})
                </span>
              </label>
            </span>
          </div>
        </fieldset>
      </template>

      <template #field_provideFeedback="{ field }">
        <fieldset>
          <legend class="mb-1 block">{{ field.label }}</legend>

          <div class="space-x-3">
            <span>
              <Field
                :id="`${field.name}-yes`"
                :name="field.name"
                type="radio"
                :value="true"
                class="mr-2"
              />
              <label :for="`${field.name}-yes`">
                {{ $t('messages.yes') }}
              </label>
            </span>

            <span>
              <Field
                :id="`${field.name}-no`"
                :name="field.name"
                type="radio"
                :value="false"
                class="mr-2"
              />
              <label :for="`${field.name}-no`">
                {{ $t('messages.no') }}
              </label>
            </span>

            <span>
              <Field
                :id="`${field.name}-default`"
                :name="field.name"
                type="radio"
                :value="null"
                class="mr-2"
              />
              <label :for="`${field.name}-default`">
                {{ $t('actions.useDefault') }}
                <span class="text-muted">
                  ({{
                    defaults[field.name]
                      ? $t('messages.yes')
                      : $t('messages.no')
                  }})
                </span>
              </label>
            </span>
          </div>
        </fieldset>
      </template>
    </DynamicForm>
  </PatientLayout>
</template>
