import { createRouter, createWebHistory } from 'vue-router/auto'
import { useAuth } from '@/plugins/auth'

const router = createRouter({
  history: createWebHistory(),
})

router.beforeEach(async (to, _from, next) => {
  const { isLoggedIn, self } = useAuth()

  const loggedIn = await isLoggedIn()

  // route guard, redirect to login if page requires auth and not logged in
  if (to.meta.requiresAuth && !loggedIn) {
    // Don't allow redirecting to logout from the login page
    const redirect = to.fullPath === '/logout' ? undefined : to.fullPath
    next({ name: 'login', query: { redirect } })
  } else if (to.meta.requiresAuth && self.value?.pendingLicenseAgreements.length) {
    next({ name: 'license' })
  } else {
    next()
  }
})

export default router
