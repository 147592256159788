<script setup lang="ts">
import { computed } from 'vue'
import { definePage, useRoute, useRouter } from 'vue-router/auto'
import { useApi, useQuery } from '@/composables'
import { useI18n } from 'vue-i18n'
import {
  Badge,
  Btn,
  DropdownMenu,
  Empty,
  MenuItemButton,
  MenuItemDivider,
  MenuItemLink,
  Panel,
  DetailList,
} from '@/components'
import { GoalState, GoalSummary } from '@/models'
import { CheckIcon } from '@heroicons/vue/24/solid'

definePage({
  meta: { requiresAuth: true },
})

const router = useRouter()
const route = useRoute('patients-id-goals')
const userId = route.params.id

const { t } = useI18n()

const api = useApi()

// const { result: userRes } = useQuery(api.v1.usersDetail, userId)
// const user = computed(() => userRes.value?.data.data?.item)

const { result: goalsRes, execute } = useQuery(api.v1.goalsList, { userId })
const goals = computed(() => goalsRes.value?.data?.data?.items || [])

const goalsByState = computed(() =>
  goals.value.reduce((acc, goal: GoalSummary) => {
    if (!acc[goal.state]) acc[goal.state] = []
    acc[goal.state].push(goal)
    return acc
  }, {} as Record<GoalState, GoalSummary[]>)
)

const goalStates: GoalState[] = ['draft', 'active', 'inactive', 'archived']

const remove = async (id: string) => {
  if (!confirm(t('messages.confirm'))) return
  await api.v1.goalsDelete(id)
  await execute()
}

const setState = async (id: string, state: GoalState) => {
  await api.v1.goalsStateUpdate(id, { state })
  await execute()
}

const renamePrompt = async (id: string) => {
  const goal = goals.value.find((g) => g.id === id)
  if (!goal) return

  const title = prompt(
    t('messages.renameModel', { model: t('models.goal') }),
    goal.title
  )
  if (!title) return

  await api.v1.goalsTitleUpdate(id, { title })
  await execute()
}

const duplicateAndRedirect = async (goalId: string) => {
  const res = await api.v1.goalsDuplicateCreate(goalId, {})
  router.push({
    name: 'patients-id-goals-goalid',
    params: { id: route.params.id, goalid: res.data.data.item.id },
  })
}
</script>

<template>
  <PatientLayout :title="$t('pages.patientsGoals.title')" :user-id="userId">
    <template #actions>
      <Btn variant="primary" :to="{ name: 'patients-id-goals-create' }">{{
        $t('actions.createModel', { model: $t('models.goal') })
      }}</Btn>
    </template>

    <section v-if="goals" class="space-y-8">
      <section v-for="state in goalStates" :key="state">
        <header class="mb-5">
          <h2 class="font-body text-base font-semibold">
            {{ $t(`goalStates.${state}`) }}
          </h2>
        </header>

        <ul v-if="goalsByState[state]?.length" class="space-y-2">
          <Panel
            v-for="goal in goalsByState[state]"
            :key="goal.id"
            as="li"
            header-class="flex items-center gap-5"
          >
            <template #header>
              <div>
                <router-link
                  :to="{
                    name: 'patients-id-goals-goalid',
                    params: { id: route.params.id, goalid: goal.id },
                  }"
                  class="font-semibold"
                >
                  {{ goal.title }}
                </router-link>

                <div class="text-muted text-sm">
                  {{ $t('messages.itemCount', { count: goal.itemCount }) }}
                </div>
              </div>

              <div class="ml-auto">
                <Badge
                  :variant="goal.state === 'active' ? 'primary' : 'neutral'"
                  class="ml-2"
                >
                  {{ $t(`goalStates.${goal.state}`) }}
                </Badge>
              </div>

              <div>
                <DropdownMenu menu-class="right-0">
                  <MenuItemLink
                    :to="{
                      name: 'patients-id-goals-goalid',
                      params: { id: route.params.id, goalid: goal.id },
                    }"
                  >
                    {{
                      goal.state === 'draft'
                        ? $t('actions.edit')
                        : $t('actions.view')
                    }}
                  </MenuItemLink>

                  <MenuItemButton @click="renamePrompt(goal.id)">
                    {{ $t('actions.rename') }}
                  </MenuItemButton>

                  <MenuItemButton @click="duplicateAndRedirect(goal.id)">
                    {{ $t('actions.duplicate') }}
                  </MenuItemButton>

                  <MenuItemDivider />

                  <MenuItemLink
                    :to="{
                      name: 'patients-id-goals-goalid-metrics',
                      params: { id: route.params.id, goalid: goal.id },
                    }"
                    :disabled="goal.state === 'draft'"
                  >
                    {{ $t('actions.metrics') }}
                  </MenuItemLink>

                  <MenuItemDivider />

                  <MenuItemButton
                    disabled
                    class="flex items-center gap-2"
                    @click="setState(goal.id, 'draft')"
                  >
                    <CheckIcon v-if="goal.state === 'draft'" class="h-5 w-5" />
                    {{ $t('goalStates.draft') }}
                  </MenuItemButton>

                  <MenuItemButton
                    :disabled="goal.state === 'active' || goal.itemCount === 0"
                    class="flex items-center gap-2"
                    @click="setState(goal.id, 'active')"
                  >
                    <CheckIcon v-if="goal.state === 'active'" class="h-5 w-5" />
                    {{ $t('goalStates.active') }}

                    <span v-if="goal.itemCount === 0"
                      >({{ $t('messages.itemsRequired') }})</span
                    >
                  </MenuItemButton>

                  <MenuItemButton
                    :disabled="
                      goal.state === 'draft' || goal.state === 'inactive'
                    "
                    class="flex items-center gap-2"
                    @click="setState(goal.id, 'inactive')"
                  >
                    <CheckIcon
                      v-if="goal.state === 'inactive'"
                      class="h-5 w-5"
                    />
                    {{ $t('goalStates.inactive') }}
                  </MenuItemButton>

                  <MenuItemButton
                    :disabled="
                      goal.state === 'draft' || goal.state === 'archived'
                    "
                    class="flex items-center gap-2"
                    @click="setState(goal.id, 'archived')"
                  >
                    <CheckIcon
                      v-if="goal.state === 'archived'"
                      class="h-5 w-5"
                    />
                    {{ $t('goalStates.archived') }}
                  </MenuItemButton>

                  <MenuItemDivider />

                  <MenuItemButton
                    :disabled="goal.state !== 'draft'"
                    @click="remove(goal.id)"
                  >
                    {{ $t('actions.delete') }}
                  </MenuItemButton>
                </DropdownMenu>
              </div>
            </template>

            <DetailList
              :entries="goal"
              :fields="['imitation']"
              i18n-prefix="details.goals"
            >
              <template #imitation-value="{ item }">
                {{ item.imitation ? $t('messages.yes') : $t('messages.no') }}
              </template>
            </DetailList>
          </Panel>
        </ul>

        <Empty
          v-else
          size="md"
          muted
          :title="
            $t('messages.noStateGoals', {
              state: $t(`goalStates.${state}`).toLowerCase(),
            })
          "
        />
      </section>
    </section>
  </PatientLayout>
</template>
