<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useIdle, useTimestamp } from '@vueuse/core'
import { ModalDialog } from '@/components'

const props = withDefaults(
  defineProps<{
    title?: string
    confirmText?: string
    idleTimeout?: number // milliseconds
    cutoffTimeout?: number // milliseconds
    disabled?: boolean
  }>(),
  {
    title: '',
    confirmText: '',
    idleTimeout: 5 * 1000, // 5 seconds
    cutoffTimeout: 5 * 1000, // 5 seconds
  }
)

const emit = defineEmits<{
  close: []
  idle: []
  cutoff: []
}>()

type ActiveState = 'init' | 'active' | 'idle' | 'cutoff'

const state = ref<ActiveState>('active')

const {
  timestamp: now,
  pause: pauseTimestamp,
  resume: resumeTimestamp,
} = useTimestamp({ interval: 1000, controls: true })

const { idle, lastActive, reset: resetIdle } = useIdle(props.idleTimeout)
const idleAt = ref<number>(0)
const activeAt = ref<number>(0)

watch(idle, (value) => {
  if (state.value === 'active' && value) {
    setState('idle')
    emit('idle')
  }
})

const total = computed(() => props.idleTimeout + props.cutoffTimeout)
const idleFor = computed(() => Math.floor(now.value - activeAt.value))
const remaining = computed(() => total.value - idleFor.value)

watch(idleFor, (value) => {
  if (state.value === 'idle' && value >= total.value) {
    setState('cutoff')
    emit('cutoff')
  }
})

const setState = (value: ActiveState) => {
  state.value = value

  if (value === 'init' || value === 'cutoff') {
    resetIdle()
    pauseTimestamp()
  } else if (value === 'active') {
    resetIdle()
    resumeTimestamp()
  } else if (value === 'idle') {
    // hand off the value so it is not automatically reset
    activeAt.value = lastActive.value
    idleAt.value = Date.now()
  }
}

watch(
  () => props.disabled,
  (value) => {
    // if we are disabled
    if (value) {
      // bail if we are in cutoff state to keep showing the cutoff message
      if (state.value === 'cutoff') return
      setState('init')
    } else {
      setState('active')
    }
  },
  { immediate: true }
)
</script>

<template>
  <ModalDialog
    :title="title || $t('components.idleMonitor.title')"
    :open="state === 'idle'"
    confirmable
    closeable
    :confirm-text="confirmText || $t('actions.iAmBack')"
    @close="setState('active')"
  >
    <slot
      :state="state"
      :active-at="activeAt"
      :idle-at="idleAt"
      :idle-for="idleFor"
      :total="total"
      :remaining="remaining"
    >
      {{
        $t('components.idleMonitor.message', {
          time: idleFor,
          cutoff: remaining,
        })
      }}
    </slot>
  </ModalDialog>

  <ModalDialog
    :title="title || $t('components.idleMonitor.cutoffTitle')"
    :open="state === 'cutoff'"
    confirmable
    closeable
    :confirm-text="confirmText || $t('actions.iAmBack')"
    @close="setState('init')"
  >
    <slot name="cutoff" :total="total">
      {{ $t('components.idleMonitor.cutoffMessage') }}
    </slot>
  </ModalDialog>
</template>
