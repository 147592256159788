import _definePage_default_0 from '/app/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/app/src/pages/[...path].vue?definePage&vue'
import _definePage_default_3 from '/app/src/pages/bench.vue?definePage&vue'
import _definePage_default_4 from '/app/src/pages/login.vue?definePage&vue'
import _definePage_default_5 from '/app/src/pages/logout.vue?definePage&vue'
import _definePage_default_6 from '/app/src/pages/password-reset/index.vue?definePage&vue'
import _definePage_default_7 from '/app/src/pages/password-reset/[id].vue?definePage&vue'
import _definePage_default_8 from '/app/src/pages/password-reset/complete.vue?definePage&vue'
import _definePage_default_9 from '/app/src/pages/password-reset/invalid.vue?definePage&vue'
import _definePage_default_10 from '/app/src/pages/password-reset/sent.vue?definePage&vue'
import _definePage_default_11 from '/app/src/pages/patients/index.vue?definePage&vue'
import _definePage_default_12 from '/app/src/pages/patients/[id]/index.vue?definePage&vue'
import _definePage_default_13 from '/app/src/pages/patients/[id]/goals/index.vue?definePage&vue'
import _definePage_default_14 from '/app/src/pages/patients/[id]/goals/[goalid]/index.vue?definePage&vue'
import _definePage_default_15 from '/app/src/pages/patients/[id]/goals/[goalid]/metrics.vue?definePage&vue'
import _definePage_default_16 from '/app/src/pages/patients/[id]/goals/create.vue?definePage&vue'
import _definePage_default_17 from '/app/src/pages/patients/[id]/passcode.vue?definePage&vue'
import _definePage_default_18 from '/app/src/pages/patients/[id]/record/index.vue?definePage&vue'
import _definePage_default_19 from '/app/src/pages/patients/[id]/record/versions.vue?definePage&vue'
import _definePage_default_20 from '/app/src/pages/patients/[id]/recordings.vue?definePage&vue'
import _definePage_default_21 from '/app/src/pages/patients/[id]/summary.vue?definePage&vue'
import _definePage_default_22 from '/app/src/pages/profile.vue?definePage&vue'
import _definePage_default_23 from '/app/src/pages/users/index.vue?definePage&vue'
import _definePage_default_24 from '/app/src/pages/users/create.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'home',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: 'path',
    component: () => import('/app/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/bench',
    name: 'bench',
    component: () => import('/app/src/pages/bench.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  {
    path: '/license',
    name: 'license',
    component: () => import('/app/src/pages/license.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/app/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  _mergeRouteRecord(
  {
    path: '/logout',
    name: 'logout',
    component: () => import('/app/src/pages/logout.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  {
    path: '/password-reset',
    /* internal name: 'password-reset' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'password-reset',
        component: () => import('/app/src/pages/password-reset/index.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'password-reset-id',
        component: () => import('/app/src/pages/password-reset/[id].vue'),
        /* no children */
      },
  _definePage_default_7
  ),
  _mergeRouteRecord(
      {
        path: 'complete',
        name: 'password-reset-complete',
        component: () => import('/app/src/pages/password-reset/complete.vue'),
        /* no children */
      },
  _definePage_default_8
  ),
  _mergeRouteRecord(
      {
        path: 'invalid',
        name: 'password-reset-invalid',
        component: () => import('/app/src/pages/password-reset/invalid.vue'),
        /* no children */
      },
  _definePage_default_9
  ),
  _mergeRouteRecord(
      {
        path: 'sent',
        name: 'password-reset-sent',
        component: () => import('/app/src/pages/password-reset/sent.vue'),
        /* no children */
      },
  _definePage_default_10
  )
    ],
  },
  {
    path: '/patients',
    /* internal name: 'patients' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'patients',
        component: () => import('/app/src/pages/patients/index.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
      {
        path: ':id',
        /* internal name: 'patients-id' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'patients-id',
            component: () => import('/app/src/pages/patients/[id]/index.vue'),
            /* no children */
          },
  _definePage_default_12
  ),
          {
            path: 'goals',
            /* internal name: 'patients-id-goals' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'patients-id-goals',
                component: () => import('/app/src/pages/patients/[id]/goals/index.vue'),
                /* no children */
              },
  _definePage_default_13
  ),
              {
                path: ':goalid',
                /* internal name: 'patients-id-goals-goalid' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'patients-id-goals-goalid',
                    component: () => import('/app/src/pages/patients/[id]/goals/[goalid]/index.vue'),
                    /* no children */
                  },
  _definePage_default_14
  ),
  _mergeRouteRecord(
                  {
                    path: 'metrics',
                    name: 'patients-id-goals-goalid-metrics',
                    component: () => import('/app/src/pages/patients/[id]/goals/[goalid]/metrics.vue'),
                    /* no children */
                  },
  _definePage_default_15
  )
                ],
              },
  _mergeRouteRecord(
              {
                path: 'create',
                name: 'patients-id-goals-create',
                component: () => import('/app/src/pages/patients/[id]/goals/create.vue'),
                /* no children */
              },
  _definePage_default_16
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'passcode',
            name: 'patients-id-passcode',
            component: () => import('/app/src/pages/patients/[id]/passcode.vue'),
            /* no children */
          },
  _definePage_default_17
  ),
          {
            path: 'record',
            /* internal name: 'patients-id-record' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'patients-id-record',
                component: () => import('/app/src/pages/patients/[id]/record/index.vue'),
                /* no children */
              },
  _definePage_default_18
  ),
  _mergeRouteRecord(
              {
                path: 'versions',
                name: 'patients-id-record-versions',
                component: () => import('/app/src/pages/patients/[id]/record/versions.vue'),
                /* no children */
              },
  _definePage_default_19
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'recordings',
            name: 'patients-id-recordings',
            component: () => import('/app/src/pages/patients/[id]/recordings.vue'),
            /* no children */
          },
  _definePage_default_20
  ),
  _mergeRouteRecord(
          {
            path: 'summary',
            name: 'patients-id-summary',
            component: () => import('/app/src/pages/patients/[id]/summary.vue'),
            /* no children */
          },
  _definePage_default_21
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/profile',
    name: 'profile',
    component: () => import('/app/src/pages/profile.vue'),
    /* no children */
  },
  _definePage_default_22
  ),
  {
    path: '/users',
    /* internal name: 'users' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'users',
        component: () => import('/app/src/pages/users/index.vue'),
        /* no children */
      },
  _definePage_default_23
  ),
      {
        path: ':id',
        name: 'users-id',
        component: () => import('/app/src/pages/users/[id].vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'create',
        name: 'users-create',
        component: () => import('/app/src/pages/users/create.vue'),
        /* no children */
      },
  _definePage_default_24
  )
    ],
  }
]
