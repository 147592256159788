<script setup lang="ts">
import { definePage, useRouter } from 'vue-router/auto'
import { useAuth } from '@/plugins/auth'
import { Background, Btn } from '@/components'
import { PanelLayout } from '@/layouts'

definePage({
  meta: { requiresAuth: true },
})

const router = useRouter()
const { logout: authLogout } = useAuth()

const logout = async () => {
  await authLogout()
  router.replace({ name: 'login' })
}
</script>

<template>
  <Background class="bg-gray-100" />

  <PanelLayout :title="$t('pages.logout.title')">
    <template #header>
      <h1>{{ $t('pages.logout.title') }}</h1>
    </template>

    <p class="mb-8">{{ $t('pages.logout.confirm') }}</p>

    <template #actions>
      <Btn variant="danger" class="!block w-full" @click="logout">
        {{ $t('actions.logout') }}
      </Btn>
    </template>
  </PanelLayout>
</template>
