<script setup lang="ts">
import { computed, useAttrs } from 'vue'
import { Variant } from '@@/types'

const props = withDefaults(
  defineProps<{
    variant?: Variant
    size?: 'sm' | 'md' | 'lg'
    outline?: boolean
    borderless?: boolean
  }>(),
  {
    variant: 'neutral',
    size: 'md',
  }
)

const attrs = useAttrs()

const isDisabled = computed(
  () => 'disabled' in attrs && attrs.disabled !== false
)

type State = 'filled' | 'outline' | 'borderless'

const state = computed<State>(() => {
  if (props.outline) return 'outline'
  if (props.borderless) return 'borderless'
  return 'filled'
})
</script>

<template>
  <component
    :is="
      isDisabled && ($attrs.to || $attrs.href)
        ? 'span'
        : $attrs.to
        ? 'RouterLink'
        : $attrs.href
        ? 'a'
        : 'button'
    "
    class="inline-block rounded border text-center transition-colors duration-100 hover:no-underline focus:outline-none focus:ring-2 focus:ring-offset-2 dark:ring-offset-gray-800"
    :class="[
      {
        'px-2 py-1 text-sm': size === 'sm',
        'text-md px-3 py-2': size === 'md',
        'px-4 py-3 text-lg': size === 'lg',
      },

      {
        'focus:ring-neutral-500 focus:ring-opacity-40': variant === 'neutral',
        'focus:ring-primary-800 focus:ring-opacity-50': variant === 'primary',
        'focus:ring-secondary-500 focus:ring-opacity-50':
          variant === 'secondary',
        'focus:ring-danger-500 focus:ring-opacity-50': variant === 'danger',
        'focus:ring-warning-500 focus:ring-opacity-50': variant === 'warning',
        'focus:ring-success-500 focus:ring-opacity-50': variant === 'success',
        'focus:ring-info-400 focus:ring-opacity-50': variant === 'info',
      },

      isDisabled && ['cursor-not-allowed opacity-20 focus:ring-0'],

      state === 'borderless' && ['border-transparent bg-transparent'],

      state === 'outline' && [
        'bg-white dark:bg-gray-900',
        {
          'border-disabled-500 !bg-white dark:!bg-gray-900 dark:hover:bg-gray-900':
            isDisabled,
          'border-neutral-400': variant === 'neutral',
          'border-primary-500': variant === 'primary',
          'border-secondary-500': variant === 'secondary',
          'border-danger-500': variant === 'danger',
          'border-warning-500': variant === 'warning',
          'border-success-500': variant === 'success',
          'border-info-500': variant === 'info',
        },
      ],

      (state === 'borderless' || state === 'outline') && [
        ['dark:hover:text-white'],
        {
          '!bg-transparent !text-disabled-600 dark:!text-disabled-500':
            isDisabled,
          'text-neutral-500 hover:bg-neutral-200 dark:hover:bg-neutral-700':
            variant === 'neutral',
          'text-primary-600 hover:bg-primary-100 dark:hover:bg-primary-900':
            variant === 'primary',
          'text-secondary-600 hover:bg-secondary-100 dark:hover:bg-secondary-900':
            variant === 'secondary',
          'text-danger-600 hover:bg-danger-100 dark:hover:bg-danger-900':
            variant === 'danger',
          'text-warning-600 hover:bg-warning-100 dark:hover:bg-warning-900':
            variant === 'warning',
          'text-success-600 hover:bg-success-100 dark:hover:bg-success-900':
            variant === 'success',
          'text-info-600 hover:bg-info-100 dark:hover:bg-info-900':
            variant === 'info',
        },
      ],

      state === 'filled' && [
        'border-transparent !text-white',
        {
          '!bg-disabled-500': isDisabled,
          'bg-neutral-400 hover:bg-neutral-500': variant === 'neutral',
          'bg-primary-800 hover:bg-primary-950': variant === 'primary',
          'bg-secondary-500 hover:bg-secondary-600': variant === 'secondary',
          'bg-danger-500 hover:bg-danger-600': variant === 'danger',
          'bg-warning-500 hover:bg-warning-600': variant === 'warning',
          'bg-success-500 hover:bg-success-600': variant === 'success',
          'bg-info-600 hover:bg-info-700': variant === 'info',
        },
      ],
    ]"
  >
    <slot />
  </component>
</template>
