---
# brand
title: SpeakSuite

# top level site navigation
nav:
  home: Home
  patients: Patients
  summary: Summary
  patientRecord: Patient Record
  recordings: Recordings
  passcode: Passcode
  users: Users
  goals: Goals
  profile: Profile
  login: Sign In
  logout: Sign Out
  userDetails: User Details
  userRelationships: Relationships

# used in combination with "<action>Model" keys
# intended to dry up "create item", "create other", etc.
models:
  item: item
  items: items
  user: User
  users: Users
  patient: Patient
  patients: Patients
  record: Record
  records: Records
  recording: Recording
  recordings: Recordings
  goal: Goal
  goals: Goals
  role: Role
  roles: Roles
  version: Version
  versions: Versions
  email: Email
  emails: Emails

# used for buttons and menu items
# note that "<action>Model" keys should be used with models keys
actions:
  login: Sign In
  logout: Sign Out
  create: Create
  createModel: Create {model}
  edit: Edit
  view: View
  delete: Delete
  update: Update
  confirm: Confirm
  cancel: Cancel
  save: Save
  add: Add
  addModel: Add {model}
  remove: Remove
  removeModel: Remove {model}
  moveUp: Move Up
  moveDown: Move Down
  moveTop: Move to Top
  moveBottom: Move to Bottom
  metrics: Metrics
  clear: Clear
  submit: Submit
  activateGoal: Activate this Goal
  selectAll: Select All
  selectNone: Select None
  generatePasscode: Generate Passcode
  play: Play
  activate: Activate
  deactivate: Deactivate
  archive: Archive
  rename: Rename
  duplicate: Duplicate
  useDefault: Use default
  updatePassword: Update Password
  forgotPassword: I forgot my password
  send: Send
  returnToSignIn: Return to sign in
  returnToHome: Return to home
  accept: Accept
  iAmBack: I'm Back
  sendReset: Send a reset link
  expandRoles: Show More Roles
  collapseRoles: Show Fewer Roles
  resendEmails: Resend Emails
  refresh: Refresh
  refreshModel: Refresh {model}

# used for prompts and alerts
messages:
  noItems: No items
  noModels: No %{model}
  noModelsLong: There are no %{model}
  noStateGoals: No %{state} goals
  resultCount: '%{count} of %{total} results'
  itemCount: '%{count} items'
  noResults: No results found
  na: —
  latest: Latest
  passcodeExpires: Passcode expires in %{time}
  redirecting: Redirecting…
  creating: Creating…
  updatedAt: Updated %{time}
  confirm: Are you sure?
  confirmRemove: Are you sure you want to remove "{name}"?
  confirmRemoveModel: Are you sure you want to remove this {model}?
  renameModel: Rename %{model}
  itemsRequired: Items required
  defaultModel: '%{model} (default)'
  invalidPassword: Current password does not match our records
  'yes': 'Yes'
  'no': 'No'
  idle: You have been idle since %{time}. You will be automatically signed out %{cutoff} unless you return.
  you: You
  removePrimaryEmail: A primary email address can only be removed by setting another email address as primary or if it is the only email address on this user
  outdated: A newer version of SpeakSuite is available! Click here to update
  searching: Searching...
  error: An error has occurred
  errorDuplicateEmail: This email address is already in use
  errorInvalidLogin: The organization, email and password do not match
  errorInvalidPassword: New password does not meet all requirements
  noSubject: Subject not available

# form labels should have no suffix (eg: "email")
# placeholders should have "<field>Placeholder" suffix
# help text should have "<field>Help" suffix
forms:
  login:
    organization: Organization
    organizationPlaceholder: Organization
    email: Email
    emailPlaceholder: Email
    password: Password
    passwordPlaceholder: Password
    showPassword: Show password

  sounds:
    name: Name
    phoneme: Phoneme
    initial: Initial
    medial: Medial
    final: Final
    single: Single
    multiple: Multiple
    locationError: At least one location option must be selected
    occurrenceError: At least one occurrence option must be selected

  goals:
    imitation: Include imitation

  filter:
    resultCount: '%{count} of %{total} results'

  rolePicker:
    roleSelect: Role
    rolePlaceholder: Select a role

  autocomplete:
    placeholder: Search…
    noResults: No Results

  relationships:
    select: Select Relationship Type
    search: Search Users
    searchPlaceholder: Search by name

  patientsRecordings:
    goal: Goal
    rating: Rating

  userDetails:
    fullName: Full Name
    email: Email Address
    emailTooltip: An email address is optional, but required for most users to login. A patient can alternatively login with a passcode that a caregiver or SLP shares with them.

# component-specific copy only
components:
  idleMonitor:
    title: Idle Notice
    message: Idle since %{time}. Cutoff will trigger %{cutoff}.
    cutoffTitle: Logged Out
    cutoffMessage: You have been logged out due to inactivity.

# page-specific headings and copy only
# make sure keys that belong in actions or messages do not go here!
pages:
  home:
    title: Home

  patients:
    title: Patients
  patientsEdit:
    title: Edit Patient
  patientsSummary:
    title: Patient Summary
    record: Patient Record
    goals: Goals
    recordings: Recordings
    passcode: Passcode
  patientsRecord:
    title: Patient Record
    versions: Versions
    noRecord: No Patient Record
  patientsGoals:
    title: Goals
  patientsRecordings:
    title: Recordings
    all: All
    none: Unrated
    good: Good
    fair: Fair
    poor: Poor
    unrated: Unrated
    ignored: Ignored
    showImitation: Show Flashcard

  patientsPasscode:
    title: Passcode
    description: A temporary passcode allows a patient to sign in without an email address or password

  goals:
    title: Goals
  goalsEdit:
    title: Edit Goal
    targetSounds: Target Sounds
    avoidSounds: Avoid Sounds
    searchUtterances: Search Utterances
    clusters: Clusters
    items: Goal Items

  goalsMetrics:
    title: Goal Metrics
    daily: Daily
    weekly: Weekly
    monthly: Monthly
    yearly: Yearly
    includeUnrated: Include Unrated Recordings

  licenseAgreement:
    description: Please read and accept the following license agreement before continuing.

  users:
    title: Users
  usersCreate:
    title: New User
  usersEdit:
    title: Edit User

    rolesTitle: Roles
    rolesTooltip: A user can have one or more roles. The permissions from each role are combined. For example, a user who can create goals, rate recordings and view metrics (SLP role), and manages users (director role).

    patientTitle: Patient
    patientDescription: A patient can practice the goals that are created by an SLP
    patientSlpLabel: Receives treatment from these SLPs
    patientSlpPlaceholder: Search for SLPs
    patientCaregiverLabel: Has these caregivers
    patientCaregiverPlaceholder: Search for caregivers

    patientCaregiverEditTitle: Create a new caregiver

    caregiverTitle: Caregiver
    caregiverDescription: A caregiver can assist the patient while setting up the SpeakSuite app
    caregiverPatientLabel: Provides care to these patients
    caregiverPatientPlaceholder: Search for patients

    slpTitle: Speech Language Pathologist (SLP)
    slpDescription: The SLP can create goals for patients, rate recordings and view metrics
    slpPatientLabel: Provides treatment to these patients
    slpPatientPlaceholder: Search for patients
    slpAssistantLabel: Other SLPs with patient access
    slpAssistantPlaceholder: Search for SLPs
    slpAssistantTooltip: These SLPs can also create goals, rate recordings and view metrics for the patients

    ownerTitle: Owner
    ownerDescription: An owner can accept user license agreements, create users and update all roles

    directorTitle: Director
    directorDescription: A director can create users and update the patient, caregiver and SLP roles

    auditTitle: Audit
    auditDescription: An audit specialist can see users and their roles, but not create user or update roles

    billingTitle: Billing
    billingDescription: A billing specialist can see invoices and update billing information

    emailsTitle: Email History
    emailsTooltip: Emails that have been sent to this user in the last 90 days.

  profile:
    title: Profile
    password: Change Password

  passwordReset:
    title: Forgot my Password
    description: Enter your email address and we will send you a link to reset your password.
  passwordResetSent:
    title: Reset Link Sent
    description: You will receive an email with a password reset link if an account with this email address exists.
  passwordResetEdit:
    title: Update Your Password
  passwordResetComplete:
    title: Password Updated
    description: Your password was successfully changed.
  passwordResetInvalid:
    title: Reset Link Expired
    description: The password reset link has expired.
  login:
    title: Sign In

  logout:
    title: Sign Out
    confirm: Are you sure you want to sign out?
  notFound:
    title: Page Not Found
    message: Sorry, the page you are looking for does not exist.

roles:
  owner: Owner
  assistant: Assistant
  audit: Audit
  billing: Billing
  patient: Patient
  compliance: Compliance
  customer-success: Customer Success
  technical-support: Technical Support
  director: Director
  caregiver: Caregiver
  speech-language-pathologist: Speech Language Pathologist

relationships:
  assistant-to-speech-language-pathologist: Assistant to Speech Language Pathologist
  patient-to-caregiver: Patient to Caregiver
  patient-to-speech-language-pathologist: Patient to Speech Language Pathologist

userStates:
  active: Active
  inactive: Inactive

goalStates:
  draft: Draft
  active: Active
  inactive: Inactive
  archived: Archived

ratings:
  good: Good
  fair: Fair
  poor: Poor
  unrated: Unrated
  ignored: Ignored

clusters:
  with: Include Clusters
  without: Exclude Clusters
  only: Only Clusters

details:
  users:
    roles: Roles
    activeAt: Last Active
    inactiveValue: Not active yet

  patients:
    dateOfBirth: Date of Birth
    dateOfBirthValue: '%{year}/%{month}'
    age: Age
    ageValue: '%{years} years, %{months} months'
    language: Language
    activeAt: Last Active

  patientRecord:
    birthYear: Birth Year
    birthMonth: Birth Month
    playSeconds: Play Seconds
    practiceCount: Practice Count
    language: Language
    automaticallyAdvance: Auto Advance
    provideFeedback: Provide Neutral Feedback

  recordings:
    imitation: Imitation
    ratedBy: Rated By
    media: Media
    createdAt: Created On
    ratedOn: Rated On

  goals:
    imitation: Imitation

  emails:
    to: Sent To
    subject: Subject
    status: Status
    createdAt: Sent On

languages:
  en-US: English (US)

passwordRules:
  minLength: Must be at least 8 characters long
  uppercase: Must contain at least one uppercase letter
  lowercase: Must contain at least one lowercase letter
  numbers: Must contain at least one number
  characters: Must contain at least one special character

emailStatuses:
  queued: Queued
  processing: Processing
  delivered: Delivered
  bounced: Bounced
  spam: Spam
