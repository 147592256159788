<script setup lang="ts">
import { computed } from 'vue'
import { useApi, useQuery, useSearchQuery, DataParam } from '@/composables'
import {
  Badge,
  DetailList,
  DropdownMenu,
  MenuItemLink,
  SearchBar,
  TransitionList,
  Panel,
  DateTimeFormat,
} from '@/components'
import { definePage } from 'vue-router/auto'

definePage({
  meta: { requiresAuth: true },
})

const { term } = useSearchQuery('term')

const api = useApi()

const data = computed<DataParam<'patientsList'>>(() => ({
  search: term.value || undefined,
}))

const { result } = useQuery(api.v1.patientsList, data)

const patients = computed(() => result.value?.data?.data?.items || [])

const defaultLanguage = computed(
  () => result.value?.data?.meta?.defaults?.language
)

const total = computed(() => result.value?.data?.meta?.totalItems || 0)
const filteredTotal = computed(() => patients.value.length)
</script>

<template>
  <PageLayout :title="$t('pages.patients.title')">
    <template #default>
      <SearchBar
        v-model="term"
        :count="filteredTotal"
        :total="total"
        class="mb-5"
      />

      <TransitionList
        v-slot="{ item: patient }"
        :items="patients"
        item-key="userId"
      >
        <Panel header-class="flex gap-3 items-center">
          <template #header>
            <div class="mr-auto">
              <router-link
                :to="{
                  name: 'patients-id',
                  params: { id: patient.userId ?? '' },
                }"
                class="font-semibold"
              >
                {{ patient.fullName }}
              </router-link>
            </div>

            <div v-if="!patient.active">
              <Badge variant="warning" outline>{{
                $t('userStates.inactive')
              }}</Badge>
            </div>

            <div>
              <DropdownMenu menu-class="right-0">
                <MenuItemLink
                  :to="{
                    name: 'patients-id-summary',
                    params: { id: patient.userId as string },
                  }"
                >
                  {{ $t('nav.summary') }}
                </MenuItemLink>

                <MenuItemLink
                  :to="{
                    name: 'patients-id-record',
                    params: { id: patient.userId as string },
                  }"
                >
                  {{ $t('nav.patientRecord') }}
                </MenuItemLink>

                <MenuItemLink
                  :to="{
                    name: 'patients-id-goals',
                    params: { id: patient.userId as string },
                  }"
                >
                  {{ $t('nav.goals') }}
                </MenuItemLink>

                <MenuItemLink
                  :to="{
                    name: 'patients-id-recordings',
                    params: { id: patient.userId as string },
                  }"
                >
                  {{ $t('nav.recordings') }}
                </MenuItemLink>

                <MenuItemLink
                  :to="{
                    name: 'patients-id-passcode',
                    params: { id: patient.userId as string },
                  }"
                >
                  {{ $t('nav.passcode') }}
                </MenuItemLink>
              </DropdownMenu>
            </div>
          </template>

          <DetailList
            :entries="patient"
            :fields="[
              'dateOfBirth',
              'age',
              'language',
              patient.activeAt ? 'activeAt' : '',
            ]"
            i18n-prefix="details.patients"
          >
            <template #dateOfBirth-value="{ item }">
              <DateTimeFormat
                v-if="item.birthYear && item.birthMonth"
                :date="new Date(item.birthYear, item.birthMonth - 1)"
                :options="{ month: 'long', year: 'numeric' }"
              />
              <span v-else>{{ $t('messages.na') }}</span>
            </template>

            <template #age-value="{ item }">
              {{
                item.ageYears !== null && item.ageMonths !== null
                  ? $t('details.patients.ageValue', {
                      years: item.ageYears,
                      months: item.ageMonths,
                    })
                  : $t('messages.na')
              }}
            </template>

            <template #language-value="{ item }">
              <span v-if="item.language">
                {{ item.language?.name?.[0]?.value }}
              </span>

              <span v-else>
                {{
                  $t('messages.defaultModel', {
                    model: defaultLanguage?.name?.[0].value,
                  })
                }}
              </span>
            </template>

            <template #activeAt-value="{ item }">
              <DateTimeFormat v-if="item.activeAt" :date="item.activeAt" />
              <span v-else>{{ $t('messages.na') }}</span>
            </template>
          </DetailList>
        </Panel>
      </TransitionList>
    </template>
  </PageLayout>
</template>
