<script setup lang="ts"></script>

<template>
  <svg
    id="a"
    data-name="Wordmark"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1693.419 310.854"
    class="h-6"
  >
    <path
      d="m87.546,247.297c-16.84,0-33.193-2.443-49.054-7.333-14.014-4.318-26.117-10.374-36.312-18.17-2.259-1.727-2.862-4.872-1.338-7.272l16.956-26.695c1.753-2.76,5.485-3.307,8.026-1.249,4.038,3.27,8.669,6.181,13.89,8.734,7.336,3.586,15.158,6.33,23.47,8.23,8.309,1.903,16.481,2.852,24.524,2.852,12.385,0,22.434-2.172,30.147-6.519,7.714-4.343,11.57-10.699,11.57-19.067,0-6.734-2.716-12.358-8.145-16.867-5.438-4.507-15.541-8.824-30.315-12.955l-20.859-5.866c-22.925-6.409-39.436-15.101-49.539-26.075-10.103-10.971-15.158-24.281-15.158-39.926,0-10.105,2.12-19.419,6.357-27.95,4.237-8.526,10.184-15.888,17.844-22.081,7.66-6.192,16.73-11,27.216-14.422,10.483-3.423,21.862-5.134,34.139-5.134,16.625,0,32.076,2.419,46.365,7.252,12.333,4.174,22.665,9.666,30.994,16.472,2.198,1.797,2.694,4.957,1.099,7.306l-17.647,25.986c-1.798,2.647-5.436,3.111-7.944,1.124-3.333-2.641-7.076-5.046-11.228-7.214-6.138-3.204-12.792-5.704-19.964-7.496-7.172-1.794-14.397-2.689-21.676-2.689-7.821,0-14.693,1.059-20.613,3.177-5.923,2.119-10.51,5.162-13.768,9.127-3.261,3.966-4.893,8.718-4.893,14.259,0,4.237,1.168,7.85,3.503,10.837,2.336,2.99,6.3,5.785,11.9,8.393,5.594,2.607,13.28,5.324,23.057,8.148l21.512,6.356c22.596,6.628,39.005,15.048,49.216,25.259,10.211,10.214,15.319,23.467,15.319,39.764,0,14.124-3.56,26.592-10.672,37.4-7.121,10.811-17.331,19.23-30.639,25.259-13.31,6.031-29.09,9.045-47.341,9.045Z"
      style="fill: currentColor; stroke-width: 0px"
    />
    <path
      d="m1018.884,250.149c-16.84,0-33.193-2.443-49.054-7.333-14.014-4.318-26.117-10.374-36.312-18.17-2.259-1.727-2.862-4.872-1.338-7.272l16.956-26.695c1.753-2.76,5.485-3.307,8.026-1.249,4.038,3.27,8.669,6.181,13.89,8.734,7.336,3.586,15.158,6.33,23.47,8.23,8.309,1.903,16.481,2.852,24.524,2.852,12.385,0,22.434-2.172,30.147-6.519,7.714-4.343,11.57-10.699,11.57-19.067,0-6.734-2.716-12.358-8.145-16.867-5.438-4.507-15.541-8.824-30.315-12.955l-20.859-5.866c-22.925-6.409-39.436-15.101-49.539-26.075-10.103-10.971-15.158-24.281-15.158-39.926,0-10.105,2.12-19.419,6.357-27.95,4.237-8.526,10.184-15.888,17.844-22.081s16.73-11,27.216-14.422c10.483-3.423,21.862-5.134,34.139-5.134,16.625,0,32.076,2.419,46.365,7.252,12.333,4.174,22.665,9.666,30.994,16.472,2.198,1.797,2.694,4.957,1.099,7.306l-17.647,25.986c-1.798,2.647-5.436,3.111-7.944,1.124-3.333-2.641-7.076-5.046-11.228-7.214-6.138-3.204-12.792-5.704-19.964-7.496-7.172-1.794-14.397-2.689-21.676-2.689-7.821,0-14.693,1.059-20.613,3.177-5.923,2.119-10.51,5.162-13.768,9.127-3.261,3.966-4.893,8.718-4.893,14.259,0,4.237,1.168,7.85,3.503,10.837,2.336,2.99,6.3,5.785,11.9,8.393,5.594,2.607,13.28,5.324,23.057,8.148l21.512,6.356c22.596,6.628,39.005,15.048,49.216,25.259,10.211,10.214,15.319,23.467,15.319,39.764,0,14.124-3.56,26.592-10.672,37.4-7.121,10.811-17.331,19.23-30.639,25.259-13.31,6.031-29.09,9.045-47.341,9.045Z"
      style="fill: currentColor; stroke-width: 0px"
    />
    <path
      d="m198.656,305.432V83.237c0-2.995,2.428-5.422,5.422-5.422h30.547c2.995,0,5.422,2.428,5.422,5.422v1.927c0,4.546,5.265,7.09,8.791,4.219,4.502-3.665,9.584-6.842,15.245-9.532,9.726-4.616,21.269-6.926,34.633-6.926,15.858,0,29.986,3.667,42.37,11.001,12.385,7.333,22.105,17.574,29.171,30.718,7.061,13.147,10.591,28.303,10.591,45.467,0,12.278-2.039,23.687-6.112,34.223-4.072,10.54-9.804,19.775-17.194,27.704-7.39,7.932-16.134,14.124-26.237,18.579-10.103,4.453-21.242,6.68-33.405,6.68-10.756,0-20.452-1.792-29.09-5.378-5.059-2.099-9.754-4.682-14.085-7.75-3.636-2.576-8.649-.11-8.649,4.346v66.917c0,2.995-2.428,5.422-5.422,5.422h-36.578c-2.995,0-5.422-2.428-5.422-5.422Zm91.257-99.365c8.474,0,16.026-1.874,22.655-5.622,6.624-3.747,11.84-9.045,15.643-15.89,3.803-6.844,5.704-14.991,5.704-24.445s-1.901-17.599-5.704-24.445c-3.803-6.844-9.019-12.141-15.643-15.888-6.63-3.749-14.181-5.622-22.655-5.622-8.366,0-15.861,1.873-22.488,5.622-6.627,3.747-11.843,9.045-15.643,15.888-3.806,6.845-5.704,14.993-5.704,24.445s1.871,17.601,5.62,24.445c3.749,6.845,8.935,12.142,15.565,15.89,6.624,3.747,14.175,5.622,22.649,5.622Z"
      style="fill: currentColor; stroke-width: 0px"
    />
    <path
      d="m756.677,239.787V15.963c0-2.995,2.428-5.422,5.422-5.422h36.739c2.995,0,5.422,2.428,5.422,5.422v109.503c0,4.929,6.045,7.301,9.397,3.688l43.423-46.804c1.026-1.106,2.466-1.734,3.975-1.734h41.023c4.67,0,7.155,5.51,4.065,9.011l-47.017,53.259c-1.607,1.821-1.808,4.488-.491,6.529l56.424,87.433c2.328,3.608-.262,8.362-4.556,8.362h-43.571c-1.88,0-3.626-.974-4.614-2.574l-36.787-59.601c-1.859-3.012-6.06-3.464-8.517-.916l-11.233,11.649c-.975,1.011-1.519,2.36-1.519,3.764v42.256c0,2.995-2.428,5.422-5.422,5.422h-36.739c-2.995,0-5.422-2.428-5.422-5.422Z"
      style="fill: currentColor; stroke-width: 0px"
    />
    <path
      d="m1192.997,250.149c-14.885,0-27.216-2.933-36.993-8.799-9.777-5.867-17.029-13.906-21.754-24.12-4.728-10.211-7.091-21.781-7.091-34.711v-96.43c0-2.995,2.428-5.422,5.422-5.422h36.742c2.995,0,5.422,2.428,5.422,5.422v88.771c0,9.778,2.689,17.547,8.067,23.304,5.378,5.76,13.115,8.637,23.221,8.637,6.519,0,12.274-1.439,17.275-4.318,4.995-2.878,8.881-6.926,11.651-12.141,2.77-5.215,4.153-11.245,4.153-18.089v-86.164c0-2.995,2.428-5.422,5.422-5.422h36.742c2.995,0,5.422,2.428,5.422,5.422v153.749c0,2.995-2.428,5.422-5.422,5.422h-30.547c-2.995,0-5.422-2.428-5.422-5.422h0c0-4.519-5.224-7.089-8.747-4.259-3.684,2.96-7.748,5.616-12.193,7.97-8.312,4.4-18.769,6.6-31.372,6.6Z"
      style="fill: currentColor; stroke-width: 0px"
    />
    <path
      d="m1343.737,54.592c-7.929,0-14.774-2.469-20.532-7.414-5.758-4.942-8.639-11.543-8.639-19.8,0-8.365,2.881-15.018,8.639-19.964,5.758-4.942,12.603-7.414,20.532-7.414s14.774,2.472,20.535,7.414c5.755,4.945,8.636,11.599,8.636,19.964,0,8.257-2.881,14.858-8.636,19.8-5.761,4.945-12.606,7.414-20.535,7.414Zm-23.793,185.246V86.089c0-2.995,2.428-5.422,5.422-5.422h36.742c2.995,0,5.422,2.428,5.422,5.422v153.749c0,2.995-2.428,5.422-5.422,5.422h-36.742c-2.995,0-5.422-2.428-5.422-5.422Z"
      style="fill: currentColor; stroke-width: 0px"
    />
    <path
      d="m1384.934,113.378v-27.289c0-2.995,2.428-5.422,5.422-5.422h27.292c2.995,0,5.422,2.428,5.422,5.422v27.289c0,2.995-2.428,5.422-5.422,5.422h-27.292c-2.995,0-5.422-2.428-5.422-5.422Zm90.284,136.771c-17.598,0-31.48-4.969-41.637-14.911-10.16-9.941-15.235-24.96-15.235-45.059V38.666c0-2.995,2.428-5.422,5.422-5.422h36.739c2.995,0,5.422,2.428,5.422,5.422v36.579c0,2.995,2.428,5.422,5.422,5.422h30.059c2.995,0,5.422,2.428,5.422,5.422v27.289c0,2.995-2.428,5.422-5.422,5.422h-30.059c-2.995,0-5.422,2.428-5.422,5.422v57.319c0,9.67,2.009,16.459,6.028,20.371,4.018,3.911,9.396,5.866,16.134,5.866,3.476,0,6.872-.407,10.187-1.222,1.131-.278,2.234-.597,3.308-.958,3.157-1.059,6.51.881,7.095,4.159l4.778,26.806c.431,2.42-.802,4.833-3.029,5.876-4.049,1.895-8.644,3.54-13.781,4.94-6.791,1.846-13.936,2.77-21.431,2.77Z"
      style="fill: currentColor; stroke-width: 0px"
    />
    <path
      d="m1611.944,250.149c-17.927,0-33.681-3.641-47.263-10.918-13.58-7.278-24.171-17.438-31.776-30.475-7.606-13.036-11.409-28.3-11.409-45.793,0-12.711,2.201-24.388,6.6-35.037,4.402-10.645,10.594-19.853,18.58-27.622,7.986-7.766,17.382-13.797,28.192-18.089,10.81-4.291,22.679-6.438,35.609-6.438,14.229,0,26.86,2.502,37.888,7.496,11.025,4.999,20.179,11.979,27.461,20.941,7.276,8.964,12.355,19.474,15.235,31.534,2.563,10.742,3.036,22.346,1.422,34.811-.345,2.665-2.678,4.627-5.365,4.627h-115.583c-3.377,0-5.919,3.058-5.359,6.388.824,4.903,2.635,9.347,5.433,13.33,3.965,5.65,9.558,10.079,16.786,13.282,7.222,3.207,15.667,4.807,25.338,4.807,9.558,0,18.685-1.303,27.378-3.911,6.86-2.058,13.412-4.961,19.662-8.71,2.708-1.625,6.226-.547,7.604,2.295l11.135,22.96c1.195,2.465.385,5.434-1.911,6.93-4.451,2.901-9.66,5.532-15.629,7.895-7.824,3.096-16.11,5.484-24.853,7.17-8.747,1.683-17.14,2.526-25.177,2.526Zm-36.848-107.882h64.988c3.454,0,6.02-3.194,5.323-6.577-1.363-6.62-4.507-12.059-9.427-16.32-6.465-5.595-15.346-8.393-26.647-8.393s-20.667,2.798-28.111,8.393c-5.532,4.161-9.31,9.446-11.329,15.857-1.097,3.483,1.552,7.039,5.204,7.039Z"
      style="fill: currentColor; stroke-width: 0px"
    />
    <path
      d="m480.394,74.936h-47.355c-16.981,0-30.774,10.862-30.774,24.258v62.387c0,70.714,36.55,85.717,93.705,85.717,17.298,0,29.05-1.313,35.946-2.444,4.397-.724,7.528-3.756,7.528-7.286v-24.53c0-4.096-4.224-7.422-9.425-7.422h-18.764c-.546.045-43.332,1.81-43.303-30.571,0-.837.89-1.539,1.953-1.539h74.251c5.058,0,7.788-1.833,8.16-5.499,1.18-8.259,2.314-39.904,2.314-49.997,0-48.991-29.898-43.075-74.236-43.075Z"
      style="fill: #00bd80; stroke-width: 0px"
    />
    <path
      d="m726.615,158.492c0-70.59-36.486-85.567-93.54-85.567-17.267,0-28.999,1.31-35.883,2.44-4.389.722-7.515,3.749-7.515,7.273v24.487c0,4.089,4.217,7.409,9.408,7.409h18.731c.545-.045,43.256-1.807,43.227,30.518,0,.836-.89,1.536-1.951,1.536h-74.12c-5.049,0-7.774,1.83-8.147,5.49-1.176,8.245-2.308,39.834-2.308,49.909,0,48.905,29.846,42.999,74.105,42.999h47.271c16.953,0,30.722-10.843,30.722-24.215v-62.278Z"
      style="fill: #00a3ba; stroke-width: 0px"
    />
  </svg>
</template>
