<script lang="ts" setup>
import { definePage } from 'vue-router/auto'
import { PanelLayout } from '@/layouts'
import { Background, Btn } from '@/components'
import background from '@/assets/background.svg'

definePage({
  meta: { chromeless: true },
})
</script>

<template>
  <PanelLayout :title="$t('pages.notFound.title')">
    <Background :url="background" class="bg-gray-100" />

    <h1 class="mb-5 text-xl">{{ $t('pages.notFound.title') }}</h1>

    <p>{{ $t('pages.notFound.message') }}</p>

    <template #actions>
      <Btn
        variant="primary"
        outline
        class="!block w-full"
        :to="{ name: 'home' }"
      >
        {{ $t('actions.returnToHome') }}
      </Btn>
    </template>
  </PanelLayout>
</template>
