import { nextTick, ref, Ref } from 'vue'
import { Plugin, InjectionKey, inject } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useJwt } from '@vueuse/integrations/useJwt'
import { getApi } from './apiClient'
import { OperationEnum, ResourceEnum, ScopeEnum, SelfDetails } from '@/models'

interface Auth {
  self: Readonly<Ref<null | SelfDetails>>
  accessToken: Readonly<Ref<null | string>>
  refreshTokenTimeout: Readonly<Ref<null | ReturnType<typeof setTimeout>>>
  login: (
    organization: string,
    email: string,
    password: string
  ) => Promise<boolean>
  logout: () => void
  isAuthenticated: Readonly<Ref<boolean>>
  isLoggedIn: () => Promise<boolean>
  refresh: () => Promise<void>
  hasPermission: (
    resource: ResourceEnum,
    action: OperationEnum,
    minScope: ScopeEnum
  ) => boolean
}

export type PermissionAction = 'create' | 'read' | 'update' | 'delete'
export type PermissionScope =
  | 'all'
  | 'access-declaration'
  | 'organization'
  | 'related-care-provider'
  | 'related-caregiver'
  | 'self'

export const key = Symbol() as InjectionKey<Auth>

let auth: Auth

export const getAuth = () => auth

export const createAuth = (): Plugin => ({
  async install(app) {
    const self = ref<SelfDetails | null>(null)
    const accessToken = ref<string | null>(null)
    const refreshTokenTimeout = ref<null | ReturnType<typeof setTimeout>>(null)
    const isAuthenticated = ref<boolean>(false)

    const cookies = useCookies()

    const login = async (
      organization: string,
      email: string,
      password: string
    ) => {
      try {
        const api = getApi()

        const result = await api.v1.authLoginCreate({
          organizationShortName: organization,
          emailAddress: email,
          password,
        })

        accessToken.value = result.data.data.accessToken
        storeRefreshToken(result.data.data.refreshToken)

        api.instance.defaults.headers.common.Authorization = `Bearer ${result.data.data.accessToken}`
        self.value = result.data.data

        isAuthenticated.value = true
        return true
      } catch (err) {
        console.error(err)
        return false
      }
    }

    const storeRefreshToken = (refreshToken: string) => {
      const refreshTokenJwt = useJwt(refreshToken)
      cookies.set('refresh_token', refreshToken, {
        expires: new Date((refreshTokenJwt.payload.value?.exp ?? 0) * 1000),
        path: '/',
      })
      startRefreshTokenTimer()
    }

    const startRefreshTokenTimer = () => {
      if (refreshTokenTimeout.value) clearTimeout(refreshTokenTimeout.value)

      const expires = getTokenExpiration(accessToken.value)
      const timeout = Math.max(expires.getTime() - Date.now() - 60 * 1000, 0) // Refresh 1 minute before it expires

      refreshTokenTimeout.value = setTimeout(refresh, timeout)
    }

    const getTokenExpiration = (token: null | string) => {
      if (!token) return new Date()

      const jwt = useJwt(token)
      return new Date((jwt.payload.value?.exp ?? 0) * 1000)
    }

    const refresh = async () => {
      const refreshToken = cookies.get('refresh_token')

      if (!refreshToken) {
        await logout()

        return
      }

      try {
        const api = getApi()
        const result = await api.v1.authRefreshCreate({ refreshToken })

        accessToken.value = result.data.data.accessToken
        storeRefreshToken(result.data.data.refreshToken)

        api.instance.defaults.headers.common.Authorization = `Bearer ${result.data.data.accessToken}`
        self.value = result.data.data

        isAuthenticated.value = true

        await nextTick()
      } catch (err) {
        console.error(err)
        await logout()
      }
    }

    const logout = async () => {
      isAuthenticated.value = false
      self.value = null
      accessToken.value = null
      if (refreshTokenTimeout.value) clearTimeout(refreshTokenTimeout.value)

      const api = getApi()
      api.instance.defaults.headers.common.Authorization = ''
      cookies.remove('refresh_token')

      // wait for the next tick to allow the router to redirect
      await nextTick()
    }

    const isLoggedIn = async () => {
      if (isAuthenticated.value) return true
      await refresh()
      return isAuthenticated.value
    }

    const hasPermission = (
      resource: ResourceEnum,
      action: OperationEnum,
      minScope: ScopeEnum
    ) => {
      if (!self.value) return false

      const scopes = self.value.scopeOrder.slice(
        self.value.scopeOrder.indexOf(minScope)
      )

      for (const scope of scopes) {
        if (self.value.permissions.includes(`${resource}:${action}:${scope}`))
          return true
      }

      return false
    }

    auth = {
      self,
      accessToken,
      refreshTokenTimeout,
      isAuthenticated,
      login,
      logout,
      refresh,
      isLoggedIn,
      hasPermission,
    }

    app.provide(key, auth)
  },
})

export const useAuth = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject(key)!
}
