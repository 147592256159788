<script setup lang="ts">
import { definePage, useRouter, LocationQueryValue } from 'vue-router/auto'
import { DynamicForm, passwordResetEmailSchema } from '@/forms'
import { PanelLayout } from '@/layouts'
import { useApi } from '@/composables'
import { Background } from '@/components'
import background from '@/assets/background.svg'

definePage({
  meta: { chromeless: true },
})

const api = useApi()
const router = useRouter()
const schema = passwordResetEmailSchema()

const paramToString = (
  param: LocationQueryValue | LocationQueryValue[]
): string => (Array.isArray(param) ? param[0] : param)?.toString() ?? ''

const send = async ({ email }: { email: string }) => {
  await api.v1.usersPasswordsResetsCreate({
    emailAddress: email,
  })
  router.replace({ name: 'password-reset-sent' })
}
</script>

<template>
  <PanelLayout :title="$t('pages.passwordReset.title')">
    <Background :url="background" class="bg-gray-100" />

    <h1 class="mb-5 text-xl">{{ $t('pages.passwordReset.title') }}</h1>

    <p class="mb-8">{{ $t('pages.passwordReset.description') }}</p>

    <DynamicForm
      :schema="schema"
      :initial-values="{
        email: paramToString($route.query.email),
      }"
      :initial-touched="{
        email: !!$route.query.email,
      }"
      :submit-btn-props="{ class: '!block w-full' }"
      :submit-text="$t('actions.sendReset')"
      @submit="send"
    />
  </PanelLayout>
</template>
