<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import { computed, ref } from 'vue'
import { DataParam, useApi, useQuery } from '@/composables'
import axios from 'axios'
import { useAuth } from '@/plugins/auth'
import { Patient, PracticeItem, RecordingMediaDetail } from '@/models'
import mime from 'mime'
import { Autocomplete, Panel, Alert, Badge, Btn } from '@/components'
import { defaultPatient } from '@/models'

definePage({
  // meta: { requiresAuth: true },
})

const throwError = () => {
  throw new Error('This is an error')
}

const auth = useAuth()
const api = useApi()

const files = ref<FileList>()
const practiceItem = ref<PracticeItem>()
const urlsFromBucket = ref<(string | undefined)[]>([])
const goalPatient = ref<Patient>(
  defaultPatient({
    userId: auth.self.value?.user.id || '',
    fullName: auth.self?.value?.user.fullName,
  })
)
const patientSearch = ref('')
const errorMsg = ref('')

const patientSearchParams = computed<DataParam<'patientsList'>>(() => ({
  search: patientSearch.value || undefined,
}))
const goalsSearchParams = computed<DataParam<'goalsList'>>(() => ({
  userId: goalPatient.value?.userId || '',
}))

const { result: patientsResult } = useQuery(
  api.v1.patientsList,
  patientSearchParams
)
const { result: goalsResult, execute: refreshGoals } = useQuery(
  api.v1.goalsList,
  goalsSearchParams
)

const goals = computed(() => goalsResult.value?.data?.data.items || [])

const onFilesChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (!target.files?.length) return

  files.value = target.files
}

const createGoal = async () => {
  try {
    const res = await api.v1.goalsCreate({
      userId: goalPatient.value?.userId || '',
      languageCode: 'en-US',
      imitation: true,
      title: 'Test',
    })

    await api.v1.goalsItemsCreate(res.data.data.item.id, {
      targetPhonemeSpecifications: [
        {
          phonemeId: 'faa33ae3-6113-41c0-99c8-2a13b31f02db',
          initial: true,
          medial: true,
          final: true,
        },
      ],
      utteranceIds: ['3640d9f9-b868-43c2-b627-06d41ab420e0'],
    })

    await api.v1.goalsStateUpdate(res.data.data.item.id, { state: 'active' })
    await refreshGoals()
  } catch (e) {
    errorMsg.value = (e as Error).message
  }
}

const createPracticeItem = async () => {
  try {
    const res = await api.v1.goalsItemsPracticeCreate({ count: 1 })
    practiceItem.value = res.data.data.items[0]
  } catch (e) {
    errorMsg.value = (e as Error).message
  }
}

const uploadMedia = async () => {
  try {
    if (!files.value) throw new Error('No file selected')
    if (!practiceItem.value) throw new Error('No practice item set')

    const mediaDetailRecord: Record<string, RecordingMediaDetail> = {}
    // FileList isn't iterable
    for (let i = 0; i < files.value.length; i++) {
      const file = files.value.item(i)
      if (!file) continue

      const fileType = file.name.split('.')?.pop() || ''
      const mediaType = mime.getType(file.name)
      if (!mediaType) throw new Error('Invalid file type')

      mediaDetailRecord[file.name] = {
        contentLength: file.size,
        mediaType,
        fileType,
      }
    }

    const mediaDetail = Object.values(mediaDetailRecord)

    const recordingsRes = await api.v1.recordingsCreate({
      practiceItemId: practiceItem.value.id,
      recordedAt: Date.now(),
      mediaDetail,
      seconds: 1,
    })

    const media = recordingsRes.data.data.item.media

    const writeUrls = await Promise.all(
      media.map((m) => api.v1.recordingsMediaWriteDetail(m.id))
    )

    const useGcpEmulator = writeUrls.some((w) =>
      w.data.data?.item?.url.includes('0.0.0.0')
    )

    await Promise.all(
      writeUrls
        .map((w) => w.data.data?.item)
        .map((w, i) => {
          if (!w) return
          const file = files.value?.item(i)
          if (!file) return

          axios.put(
            useGcpEmulator
              ? `http://localhost:3003/${w.url.replace(
                  '0.0.0.0',
                  'gcpstorage'
                )}`
              : w.url,
            file,
            {
              headers: {
                'Content-Type': mediaDetailRecord[file.name].mediaType,
              },
            }
          )
        })
    )

    const readUrls = await Promise.all(
      media.map((m) => api.v1.recordingsMediaReadDetail(m.id))
    )

    // Give google a second to process the upload
    await new Promise((resolve) => setTimeout(resolve, 1000))

    urlsFromBucket.value = readUrls.map((r) =>
      useGcpEmulator
        ? r.data.data?.item?.url.replace(
            'https://storage.googleapis.com',
            'http://localhost:3002'
          )
        : r.data.data?.item?.url
    )
  } catch (e) {
    errorMsg.value = (e as Error).message
  }
}
</script>

<template>
  <PageLayout title="Test Bench">
    <Btn @click="throwError">Throw error</Btn>

    <div v-if="auth.self" class="mt-5 space-y-5">
      <Panel>
        <div class="flex gap-x-5">
          <Autocomplete
            v-model="goalPatient"
            class="grow"
            field="fullName"
            :items="patientsResult?.data?.data?.items || []"
            required
            @input="patientSearch = $event"
          />
          <Btn variant="primary" @click="createGoal">
            Create New Goal for User
          </Btn>
        </div>
        <p class="font-bold">Existing goals for user</p>
        <ul v-if="goals.length">
          <li v-for="goal in goals" :key="goal.id">
            {{ goal.title }}
            <Badge
              :variant="goal.state === 'active' ? 'success' : 'neutral'"
              class="ml-3"
            >
              {{ goal.state }}
            </Badge>
          </li>
        </ul>
        <Alert v-else variant="warning">No goals found</Alert>
      </Panel>

      <Panel body-class="space-y-3">
        <Btn variant="primary" @click="createPracticeItem">
          Create Practice Item
        </Btn>

        <pre v-if="practiceItem">{{ practiceItem }}</pre>

        <div>
          <label for="media">Media Upload</label>
          <input
            id="media"
            type="file"
            accept="video/mp4, audio/wav"
            multiple
            @change="onFilesChange"
          />
        </div>

        <Btn variant="primary" @click="uploadMedia">Submit</Btn>

        <div v-for="(url, i) in urlsFromBucket" :key="i">
          <audio v-if="url" controls :src="url" />
        </div>
      </Panel>

      <Alert v-if="errorMsg" variant="danger">{{ errorMsg }}</Alert>
    </div>

    <!-- <Waveform src="/file_example_WAV_1MG.wav" /> -->

    <div class="mt-5 flex gap-3">
      <Btn variant="neutral">Click</Btn>
      <Btn variant="primary">Click</Btn>
      <Btn variant="secondary">Click</Btn>
      <Btn variant="info">Click</Btn>
      <Btn variant="success">Click</Btn>
      <Btn variant="warning">Click</Btn>
      <Btn variant="danger">Click</Btn>
      <Btn disabled>Click</Btn>
    </div>

    <div class="mt-5 flex gap-3">
      <Btn outline variant="neutral">Click</Btn>
      <Btn outline variant="primary">Click</Btn>
      <Btn outline variant="secondary">Click</Btn>
      <Btn outline variant="info">Click</Btn>
      <Btn outline variant="success">Click</Btn>
      <Btn outline variant="warning">Click</Btn>
      <Btn outline variant="danger">Click</Btn>
      <Btn outline disabled>Click</Btn>
    </div>

    <div class="mt-5 flex gap-3">
      <Btn borderless variant="neutral">Click</Btn>
      <Btn borderless variant="primary">Click</Btn>
      <Btn borderless variant="secondary">Click</Btn>
      <Btn borderless variant="info">Click</Btn>
      <Btn borderless variant="success">Click</Btn>
      <Btn borderless variant="warning">Click</Btn>
      <Btn borderless variant="danger">Click</Btn>
      <Btn borderless disabled>Click</Btn>
    </div>

    <Panel class="mt-5">
      <div class="flex gap-3">
        <Btn variant="neutral">Click</Btn>
        <Btn variant="primary">Click</Btn>
        <Btn variant="secondary">Click</Btn>
        <Btn variant="info">Click</Btn>
        <Btn variant="success">Click</Btn>
        <Btn variant="warning">Click</Btn>
        <Btn variant="danger">Click</Btn>
        <Btn disabled>Click</Btn>
      </div>

      <div class="mt-5 flex gap-3">
        <Btn outline variant="neutral">Click</Btn>
        <Btn outline variant="primary">Click</Btn>
        <Btn outline variant="secondary">Click</Btn>
        <Btn outline variant="info">Click</Btn>
        <Btn outline variant="success">Click</Btn>
        <Btn outline variant="warning">Click</Btn>
        <Btn outline variant="danger">Click</Btn>
        <Btn outline disabled>Click</Btn>
      </div>

      <div class="mt-5 flex gap-3">
        <Btn borderless variant="neutral">Click</Btn>
        <Btn borderless variant="primary">Click</Btn>
        <Btn borderless variant="secondary">Click</Btn>
        <Btn borderless variant="info">Click</Btn>
        <Btn borderless variant="success">Click</Btn>
        <Btn borderless variant="warning">Click</Btn>
        <Btn borderless variant="danger">Click</Btn>
        <Btn borderless disabled>Click</Btn>
      </div>
    </Panel>

    <div class="mt-5 flex gap-3">
      <Badge variant="neutral">Badge</Badge>
      <Badge variant="primary">Badge</Badge>
      <Badge variant="secondary">Badge</Badge>
      <Badge variant="info">Badge</Badge>
      <Badge variant="success">Badge</Badge>
      <Badge variant="warning">Badge</Badge>
      <Badge variant="danger">Badge</Badge>
    </div>

    <div class="mt-5 flex gap-3">
      <Badge outline variant="neutral">Badge</Badge>
      <Badge outline variant="primary">Badge</Badge>
      <Badge outline variant="secondary">Badge</Badge>
      <Badge outline variant="info">Badge</Badge>
      <Badge outline variant="success">Badge</Badge>
      <Badge outline variant="warning">Badge</Badge>
      <Badge outline variant="danger">Badge</Badge>
    </div>

    <Panel class="mt-5">
      <div class="flex gap-3">
        <Badge variant="neutral">Badge</Badge>
        <Badge variant="primary">Badge</Badge>
        <Badge variant="secondary">Badge</Badge>
        <Badge variant="info">Badge</Badge>
        <Badge variant="success">Badge</Badge>
        <Badge variant="warning">Badge</Badge>
        <Badge variant="danger">Badge</Badge>
      </div>

      <div class="mt-5 flex gap-3">
        <Badge outline variant="neutral">Badge</Badge>
        <Badge outline variant="primary">Badge</Badge>
        <Badge outline variant="secondary">Badge</Badge>
        <Badge outline variant="info">Badge</Badge>
        <Badge outline variant="success">Badge</Badge>
        <Badge outline variant="warning">Badge</Badge>
        <Badge outline variant="danger">Badge</Badge>
      </div>
    </Panel>
  </PageLayout>
</template>
