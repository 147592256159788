<script setup lang="ts">
import { definePage, useRoute } from 'vue-router/auto'
import { useApi } from '@/composables'
import { useRouter } from 'vue-router/auto'
import { Loading, PromptPanel } from '@/components'

definePage({
  meta: { requiresAuth: true },
})

const router = useRouter()
const route = useRoute('patients-id-goals-create')
const userId = route.params.id

const api = useApi()

const create = async () => {
  const res = await api.v1.goalsCreate({
    userId,
    title: 'New goal',
    languageCode: 'en-US',
    imitation: false,
  })

  const goal = res.data.data.item

  router.replace({
    name: 'patients-id-goals-goalid',
    params: { id: route.params.id, goalid: goal.id },
  })
}
create()
</script>

<template>
  <PageLayout
    :title="$t('pages.patientsGoals.title')"
    :breadcrumbs="[{ text: $t('nav.patients'), to: { name: 'patients' } }]"
  >
    <PromptPanel>
      <Loading>{{ $t('messages.creating') }}</Loading>
    </PromptPanel>
  </PageLayout>
</template>
